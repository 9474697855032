import React, {Fragment, Component} from 'react';
import {withRouter} from 'react-router-dom';
import Page from '../../UI/Page/Page';
import WithLoading from '../../Util/HOC/WithLoading';
import Menu from './Menu/Menu';
import Panel from './Panel/Panel';
import AddBrandDialog from '../../Brand/AddBrandDialog';
import {parseErrors} from '../../Util/form';
import {createMessage, notifyError} from '../../Util/notification';
import {getCountryCallingCode} from 'libphonenumber-js';
import stores from '../../../api/V3/stores';
import {deepCopy, getSafeDeep} from '../../Util/state';
import {connect} from 'react-redux';
import {ERR_VALIDATION} from '../../../api/errorCodes';
import {getTagList} from '../../Source/util';
import {mapSourceTags} from '../../../api/model/organization';
import {Helmet} from 'react-helmet';
import {containsPermission} from '../../Util/role-helpers';

const PageWithLoading = WithLoading(Page);

class PoSMenu extends Component {
  state = {
    isLoading: false,
    tab: 0,
    errors: {},
    pos: {},
    links: [],
    addBrandDialogOpen: false,
    updateLoading: false,
    createCashierDialogOpen: false,
    transactionFees: [],
    exchangeFees: [],
    sourceTimezones: [],
    checkbox: false
  };

  loadData = async () => {
    const {
      match: {
        params: {id}
      }
    } = this.props;

    this.setState({isLoading: true});
    try {
      const {data} = await stores.get(id);
      const {data: transactionFees} = await stores.getTransactionFees(
        getSafeDeep(data, 'company.0.country', data.country),
        data.source_type == 0 ? 'offline' : 'online'
      );
      const {data: exchangeFees} = await stores.getExchangeFees(
        getSafeDeep(data, 'company.0.country', data.country)
      );
      const {data: sourceTimezones} = await stores.getTimezones(
        getSafeDeep(data, 'country', data.country)
      );

      mapSourceTags(data);
      await this.setState({
        pos: data,
        isLoading: false,
        transactionFees,
        exchangeFees,
        sourceTimezones
      });

      await this.setState({
        links: this.generateLinks(data)
      });
    } catch (e) {
      console.error(e);
      createMessage('Error loading store information', 'error');
    }
  };

  initialEmailCheckbox = () => {
    this.setState({
      checkbox: !getSafeDeep(this.state.pos, 'disable_source_mails')
    });
  };

  handleEmails = async () => {
    this.setState({isLoading: true});
    try {
      await stores.sendEmails(
        getSafeDeep(this.state.pos, 'id'),
        this.state.checkbox
      );
    } catch (err) {
      notifyError(err.message);
    } finally {
      this.setState({isLoading: false});
      this.loadData().catch((error) =>
        console.error('loadData error: ', error.message)
      );
    }
  };

  updateEmailCheckbox = (active) => {
    this.setState(
      {
        checkbox: active
      },
      async () => {
        await this.handleEmails();
      }
    );
  };

  generateLinks = (data) => {
    const bankInformationData = require('../schema/bankInformationData.json');
    const bankInformationUi = require('../schema/bankInformationUi.json');

    const basicInformationUI = require(`../schema/${
      data.source_type === 0 ? 'offline' : 'online'
    }/basicInformationPosMenuUi.json`);

    const basicInformationData = require(`../schema/${
      data.source_type === 0 ? 'offline' : 'online'
    }/basicInformationPosMenuData.json`);

    const initialBuyCurrencies = this.props.hwPosOptions.filter(({id}) =>
      getSafeDeep(
        data,
        'source_config.0.configuration.buy_currencies',
        []
      ).includes(id)
    );

    const initialSellCurrencies = this.props.hwPosOptions.filter(({id}) =>
      getSafeDeep(
        data,
        'source_config.0.configuration.sell_currencies',
        []
      ).includes(id)
    );

    if (
      getSafeDeep(this.props.user, 'roles.0.name') !== 'System Administrator'
    ) {
      if (bankInformationUi.elements[3].elements) {
        bankInformationUi.elements[3].elements[0].elements[0].scope.disabled = true; //disabled transaction fee to everyone except system admin
        bankInformationUi.elements[3].elements[0].elements[1].scope.disabled = true; //disabled exchange fee to everyone except system admin
      }
    }

    return [
      {
        icon: 'information',
        name: 'Basic Information',
        schema: {
          ui: basicInformationUI,
          data: basicInformationData,
          initData: {
            form: {
              full_name: data.full_name,
              store_account_contact: data.store_account_contact,
              address: data.address,
              country: getSafeDeep(data, 'company.0.country', data.country),
              postal_code: data.postal_code,
              city: data.city,
              website: data.website,
              national_number: data.national_number,
              country_phone_prefix:
                data.country_phone_prefix ||
                `+${getCountryCallingCode(
                  data.country || getSafeDeep(data, 'company.0.country', 'SI')
                )}`,
              external_payment_method: data.external_payment_method,
              send_emails: !data.disable_source_mails
            }
          }
        }
      },
      {
        icon: 'profile',
        name: 'Public display',
        schema: {
          ui: require(`../schema/${
            data.source_type == 0 ? 'offline' : 'online'
          }/presentationUi.json`),
          data: require(`../schema/${
            data.source_type == 0 ? 'offline' : 'online'
          }/presentationData.json`),
          initData: {
            form:
              data.source_type == 0
                ? {
                    main_tag: data.main_tag,
                    tags: data.tags,
                    accepts_goc: data.accepts_goc,
                    viberate_tonight: data.viberate_tonight,
                    accepts_eurt: data.accepts_eurt,
                    franchise_id: data.franchise_id,
                    logo_url: data.logo_url,
                    language: data.language
                      ? data.language.toUpperCase()
                      : null,
                    store_image: data.store_image,
                    public_national_number: data.public_national_number,
                    public_country_phone_prefix:
                      data.public_country_phone_prefix ||
                      `+${getCountryCallingCode(
                        data.country ||
                          getSafeDeep(data, 'company.0.country', 'SI')
                      )}`,
                    public_email: data.public_email
                  }
                : {
                    main_tag: data.main_tag,
                    tags: data.tags,
                    accepts_goc: data.accepts_goc,
                    viberate_tonight: data.viberate_tonight,
                    accepts_eurt: data.accepts_eurt,
                    franchise_id: data.franchise_id,
                    logo_url: data.logo_url,
                    language: data.language
                      ? data.language.toUpperCase()
                      : null,
                    store_image: data.store_image,
                    public_national_number: data.public_national_number,
                    public_country_phone_prefix:
                      data.public_country_phone_prefix ||
                      `+${getCountryCallingCode(
                        data.country ||
                          getSafeDeep(data, 'company.0.country', 'SI')
                      )}`,
                    public_email: data.public_email
                  }
          }
        }
      },
      {
        icon: 'business',
        name: 'Bank Information',
        schema: {
          data: bankInformationData,
          ui: bankInformationUi,
          initData: {
            form: {
              bank_name: data.bank_name,
              swift: data.swift,
              TRR: data.TRR,
              mcc: data.mcc,
              transaction_fee_id: data.transaction_fee_id,
              exchange_fee: data.exchange_fee
            },
            copy: {},
            transactionFees: this.state.transactionFees.map((item) => ({
              label: `${item.value}${item.unit_name}`,
              id: item.id
            })),
            exchangeFees: this.state.exchangeFees.map((item) => ({
              label: `${item.value}${item.unit_name}`,
              id: item.id
            }))
          }
        }
      },
      data.source_type == 0
        ? {
            icon: 'location',
            name: 'Location & Opening hours',
            schema: {
              data: require('../schema/offline/locationData.json'),
              ui: require('../schema/offline/locationUi.json'),
              initData: {
                form: {
                  lat: data.lat,
                  lng: data.lng,
                  place_id: data.place_id,
                  working_hours: data.working_hours,
                  source_timezone: data.source_timezone
                },
                sourceTimezones: this.state.sourceTimezones[0][
                  data.country
                ].map((item) => {
                  return {
                    label: item,
                    id: item
                  };
                })
              }
            }
          }
        : {
            icon: 'location',
            name: 'Location',
            schema: {
              data: require('../schema/online/locationData.json'),
              ui: require('../schema/online/locationUi.json'),
              initData: {
                form: {
                  lat: data.lat,
                  lng: data.lng,
                  place_id: data.place_id
                }
              }
            }
          },
      containsPermission(this.props.user, [
        'view_source_cashiers',
        'create_cashier'
      ])
        ? {
            icon: 'cart',
            name: 'Cashiers',
            schema: {
              data: require(`../schema/${
                data.source_type == 0 ? 'offline' : 'online'
              }/cashiersData.json`),
              ui: require(`../schema/${
                data.source_type == 0 ? 'offline' : 'online'
              }/cashiersUi.json`),
              initData: {
                form: {
                  integrator_id: data.integrator_id,
                  payment_integrator_description:
                    data.payment_integrator_description,
                  pos_type: data.pos_type,
                  payment_enabled: getSafeDeep(
                    data,
                    'source_config.0.configuration.payment_enabled',
                    false
                  ),
                  buy_enabled: getSafeDeep(
                    data,
                    'source_config.0.configuration.buy_enabled',
                    false
                  ),
                  sell_enabled: getSafeDeep(
                    data,
                    'source_config.0.configuration.sell_enabled',
                    false
                  ),
                  buy_currencies: initialBuyCurrencies.length
                    ? initialBuyCurrencies
                    : this.props.hwPosOptions.filter(
                        (option) => option.hw_pos_can_buy
                      ),
                  sell_currencies: initialSellCurrencies.length
                    ? initialSellCurrencies
                    : this.props.hwPosOptions.filter(
                        (option) => option.hw_pos_can_sell
                      ),
                  send_sms_on_exchange: getSafeDeep(
                    data,
                    'source_config.0.configuration.send_sms_on_exchange',
                    false
                  ),
                  sms_number: {
                    prefix: getSafeDeep(
                      data,
                      'source_config.0.configuration.sms_number.prefix',
                      '+386'
                    ),
                    number: getSafeDeep(
                      data,
                      'source_config.0.configuration.sms_number.number',
                      ''
                    )
                  }
                },
                hwPosCryptoBuy: this.props.hwPosOptions.filter(
                  ({hw_pos_can_buy}) => hw_pos_can_buy
                ),
                hwPosCryptoSell: this.props.hwPosOptions.filter(
                  ({hw_pos_can_sell}) => hw_pos_can_sell
                )
              }
            }
          }
        : null,
      containsPermission(this.props.user, [
        'view_source_cashiers',
        'create_cashier'
      ])
        ? {
            icon: 'web',
            name: 'Tenants',
            schema: {
              data: require(`../schema/${
                data.source_type == 0 ? 'offline' : 'online'
              }/cashiersData.json`),
              ui: require(`../schema/${
                data.source_type == 0 ? 'offline' : 'online'
              }/cashiersUi.json`),
              initData: {
                form: {
                  integrator_id: data.integrator_id,
                  payment_integrator_description:
                    data.payment_integrator_description,
                  pos_type: data.pos_type,
                  payment_enabled: getSafeDeep(
                    data,
                    'source_config.0.configuration.payment_enabled',
                    false
                  ),
                  buy_enabled: getSafeDeep(
                    data,
                    'source_config.0.configuration.buy_enabled',
                    false
                  ),
                  sell_enabled: getSafeDeep(
                    data,
                    'source_config.0.configuration.sell_enabled',
                    false
                  ),
                  buy_currencies: this.props.hwPosOptions.filter(({id}) =>
                    getSafeDeep(
                      data,
                      'source_config.0.configuration.buy_currencies',
                      []
                    ).includes(id)
                  ),
                  sell_currencies: this.props.hwPosOptions.filter(({id}) =>
                    getSafeDeep(
                      data,
                      'source_config.0.configuration.sell_currencies',
                      []
                    ).includes(id)
                  ),
                  send_sms_on_exchange: getSafeDeep(
                    data,
                    'source_config.0.configuration.send_sms_on_exchange',
                    false
                  ),
                  sms_number: {
                    prefix: getSafeDeep(
                      data,
                      'source_config.0.configuration.sms_number.prefix',
                      '+386'
                    ),
                    number: getSafeDeep(
                      data,
                      'source_config.0.configuration.sms_number.number',
                      ''
                    )
                  }
                },
                hwPosCryptoBuy: this.props.hwPosOptions.filter(
                  ({hw_pos_can_buy}) => hw_pos_can_buy
                ),
                hwPosCryptoSell: this.props.hwPosOptions.filter(
                  ({hw_pos_can_sell}) => hw_pos_can_sell
                )
              }
            }
          }
        : null
    ];
  };

  update = async (data, calledBy) => {
    this.setState({updateLoading: true});
    try {
      const updateData = deepCopy(data.form);

      if (!updateData.buy_enabled) {
        updateData.buy_currencies = [];
      }

      if (!updateData.sell_enabled) {
        updateData.sell_currencies = [];
      }

      if (updateData.transaction_fee_id) {
        updateData.transaction_fee_id = parseInt(updateData.transaction_fee_id);
      }

      if (updateData.exchange_fee) {
        updateData.exchange_fee = parseInt(updateData.exchange_fee);
      }

      if (updateData.tags) {
        updateData.tags = getTagList(updateData);
      }
      if (!updateData.national_number && !this.state.pos.national_number) {
        //national_number
        updateData.national_number = null;
        updateData.country_phone_prefix = null;
      }
      if (
        !updateData.public_national_number &&
        !this.state.pos.public_national_number
      ) {
        //public_national_number
        updateData.public_national_number = null;
        updateData.public_country_phone_prefix = null;
      }
      if (updateData.language) {
        updateData.language = updateData.language.toLowerCase();
      }
      if (
        updateData.country_phone_prefix &&
        updateData.country_phone_prefix[0] != '+'
      ) {
        updateData.country_phone_prefix = `+${updateData.country_phone_prefix}`;
      }
      if (
        updateData.public_country_phone_prefix &&
        updateData.public_country_phone_prefix[0] != '+'
      ) {
        updateData.public_country_phone_prefix = `+${updateData.public_country_phone_prefix}`;
      }
      updateData.buy_currencies = getSafeDeep(
        updateData,
        'buy_currencies',
        []
      ).map(({id}) => id);
      updateData.sell_currencies = getSafeDeep(
        updateData,
        'sell_currencies',
        []
      ).map(({id}) => id);
      if (getSafeDeep(updateData, 'sms_number.prefix')) {
        updateData.sms_number.prefix = getSafeDeep(
          updateData,
          'sms_number.prefix'
        ).includes('+')
          ? `${getSafeDeep(updateData, 'sms_number.prefix')}`
          : `+${getSafeDeep(updateData, 'sms_number.prefix')}`;
      }
      const response = await stores.update(this.state.pos.id, updateData);
      mapSourceTags(response.data);
      createMessage('Data saved successfully');

      this.setState({
        pos: response.data,
        links: this.generateLinks(response.data),
        errors: {}
      });

      if (getSafeDeep(calledBy, 'dataset.name') !== 'next') return;

      setTimeout(() => {
        this.setState({tab: this.state.tab + 1});
      }, 500);
    } catch (e) {
      if (getSafeDeep(e, 'response.data.code') === ERR_VALIDATION) {
        this.setState({errors: parseErrors(e)});
      } else {
        createMessage(
          getSafeDeep(e, 'response.data.message', e.message),
          'error'
        );
      }
    } finally {
      this.setState({updateLoading: false});
    }
  };

  async componentDidMount() {
    await this.loadData();
    this.initialEmailCheckbox();
  }

  navigateTab = (tab) => {
    this.setState({tab});
  };

  toggleDialog = (val) => {
    this.setState({
      addBrandDialogOpen: val
    });
  };

  dataUpdate = (newData) => {
    const pos = {...this.state.pos, ...newData};
    this.setState({pos, links: this.generateLinks(pos)});
  };

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{`${this.state.pos.full_name} | Elicon`}</title>
        </Helmet>
        <PageWithLoading
          noCard
          isLoading={this.state.isLoading}
          title={this.state.pos.full_name}
        >
          <div className="row">
            <div className="col-xl-3 col-lg-4">
              <Menu
                handleEmails={this.handleEmails}
                dataUpdate={this.dataUpdate}
                pos={this.state.pos}
                active={this.state.links[this.state.tab]}
                navigateTab={this.navigateTab}
                links={this.state.links}
                checkbox={this.state.checkbox}
                updateEmailCheckbox={this.updateEmailCheckbox}
              />
            </div>
            <div className="col-xl-9 col-lg-8">
              <Panel
                toggleDialog={() => this.toggleDialog(true)}
                isLoading={this.state.updateLoading}
                errors={this.state.errors}
                update={this.update}
                activeTab={this.state.tab}
                tabsCount={this.state.links.length}
                active={this.state.links[this.state.tab] || {}}
                pos={this.state.pos}
              />
            </div>
          </div>
          <AddBrandDialog
            toggle={() => this.toggleDialog(false)}
            isOpen={this.state.addBrandDialogOpen}
            reloadData={() => {}}
          />
        </PageWithLoading>
      </Fragment>
    );
  }
}

const mapState = (state) => {
  return {
    categories: state.categories.data,
    user: state.user.data,
    hwPosOptions: state.hwPosOptions.data
  };
};

export default withRouter(connect(mapState)(PoSMenu));
