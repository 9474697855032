import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ListBody from "../Util/ListView/ListBody/ListBody";
import WithLoading from "../Util/HOC/WithLoading";
import qs from "query-string";
import { push } from 'connected-react-router';
import { listHeader, renderEditUser, renderFullDate, renderLink, renderName } from "../Util/listView";
import { getSafe, getSafeDeep } from "../Util/state";
import ListHeader from "../Util/ListView/ListHeader/ListHeader";

import Page from '../UI/Page/Page';
import Card from '../UI/Card/Card'
import Modal from '../UI/Modal/Modal'
import Button from '../UI/Button/Button'
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { createMessage } from "../Util/notification";
import { Helmet } from "react-helmet";
import onboarding from "../../api/V3/onboarding";

const ListBodyWithLoading = WithLoading(ListBody);

const RejectModal = (props) => {
    const [loading, setLoading] = useState(false)

    const rejectOrganization = async () => {
        setLoading(true)
        try {
            const res = await onboarding.reject(props.ids)
            props.reloadData()
            props.toggle()
            createMessage("Company rejected")
        } catch (e) {
            createMessage(getSafeDeep(e, "response.data.message", e.message), "error")
        } finally {
            setLoading(false)
        }
    }
    return (
        <Modal title="Are you sure"
            toggle={props.toggle}
            isOpen={props.isOpen}
            size="xs"
            cancelLabel="Close"
            isLoading={loading}
            submitButton={() => <Button isLoading={loading} className="ml-3" onClick={rejectOrganization} color="danger">Reject</Button>}>
            <p>Are you sure that you want to Reject selected onboardings?</p>
        </Modal>
    )
}

class Onboarding extends React.Component {

    state = {
        isLoading: false,
        count: 0,
        rowsPerPage: 10,
        sort: '',
        selected: [],
        unfinishedOnboarding: false,
        inactiveCountries: false,
        data: [],
        rejectModal: false
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(oldProps, prevState) {
        const { query, auth } = this.props;
        const { query: oldQuery, auth: oldAuth } = oldProps;
        if (JSON.stringify(query) !== JSON.stringify(oldQuery) || this.state.rowsPerPage != prevState.rowsPerPage) {
            this.loadData();
        }
    }

    loadData = async () => {
        {
            let { page, search, sort, pageSize, ...filter } = { ...this.props.query }
            this.setState({ isLoading: true })
            try {
                const response = await onboarding.get(search, (page || 0) * (pageSize || 10), (pageSize || 10), sort, filter)
                if (this.props.query.search != search) {
                    this.setState({ isLoading: false })
                } else {
                    this.setState({
                        count: response.data.count,
                        data: response.data.results,
                        isLoading: false
                    })
                }
            } catch (e) {
                createMessage(getSafeDeep(e, "response.data.message", getSafeDeep(e, "response.data", e.message)), 'error')
            } finally {
                this.setState({ isLoading: false })
            }
        }
    };

    onChangeRowsPerPage = (e) => {
        let newRowsPerPage = e.target.value;
        const query = { ...this.props.query }
        let currentItem = query.page * query.pageSize || 0;
        query.page = Math.floor(currentItem / newRowsPerPage);
        query.pageSize = newRowsPerPage;
        this.props.history.push(`${this.props.location.pathname}?${qs.stringify(query)}`);
    }

    requestSort = (sort) => {
        this.setState({ sort });
        const query = { ...this.props.query };
        query.page = 0;
        query.sort = sort
        this.props.history.push(`${this.props.location.pathname}?${qs.stringify(query)}`);
    }

    checkBoxHeader = () => {

        const StyledCheckbox = withStyles({
            root: {
                "&:hover": {
                    backgroundColor: "rgba(88, 103, 221, 0.04) !important"
                }
            },
            checked: {
                color: "rgb(88, 103, 221) !important",
                "&:hover": {
                    backgroundColor: "rgba(88, 103, 221, 0.04) !important"
                }
            },
            indeterminate: {
                color: "rgb(88, 103, 221) !important",
            }
        })(Checkbox)

        const onItemClick = (id) => {
            this.setState(prev => ({
                ...prev,
                selected: prev.selected.indexOf(id) == -1 ? prev.selected.concat(id) : prev.selected.filter(itemID => itemID != id)
            }))
        }

        const onHeaderClick = () => {
            this.setState(prev => ({
                ...prev,
                selected: prev.selected.length == prev.data.length ? [] : prev.data.map(item => item.id)
            }))
        }

        return listHeader(() => (
            <StyledCheckbox
                onClick={onHeaderClick}
                indeterminate={this.state.selected.length > 0 && this.state.selected.length < this.state.data.length}
                checked={!!this.state.selected.length == this.state.data.length}
                inputProps={{ 'aria-label': 'select all items' }}
            />
        ), "checkbox", false, (item) => (
            <StyledCheckbox
                inputProps={{ "aria-label": item.id }}
                onClick={() => onItemClick(item.id)}
                checked={!!this.state.selected.indexOf(item.id) != -1}
            />
        ))
    }

    getOnboardingListHeaders = () => {

        const StyledCheckbox = withStyles({
            root: {
                "&:hover": {
                    backgroundColor: "rgba(88, 103, 221, 0.04) !important"
                }
            },
            checked: {
                color: "rgb(88, 103, 221) !important",
                "&:hover": {
                    backgroundColor: "rgba(88, 103, 221, 0.04) !important"
                }
            },
            indeterminate: {
                color: "rgb(88, 103, 221) !important",
            }
        })(Checkbox)

        const onItemClick = (item) => {
            this.setState(prev => ({
                ...prev,
                selected: !prev.selected.find(selected => item.id == selected.id) ? prev.selected.concat(item) : prev.selected.filter(selected => selected.id != item.id)
            }))
        }

        const onHeaderClick = () => {
            this.setState(prev => ({
                ...prev,
                selected: prev.selected.length == prev.data.length ? [] : prev.data
            }))
        }

        return [
            listHeader(() => (<StyledCheckbox
                onClick={onHeaderClick}
                indeterminate={this.state.selected.length > 0 && this.state.selected.length < this.state.data.length}
                checked={this.state.selected.length == this.state.data.length}
                inputProps={{ 'aria-label': 'select all items' }}
            />), "checkbox", false, (item) => (<StyledCheckbox
                inputProps={{ "aria-label": item.id }}
                onClick={() => onItemClick(item)}
                checked={!!this.state.selected.find(selected => selected.id == item.id)}
            />)),
            listHeader('Name', "full_name", false),
            listHeader('Email', "email", false, undefined, undefined, undefined, "left"),
            listHeader('Phone', 'phone', false, (data) => { return (data.country_phone_prefix + " " + data.national_number) }, undefined, undefined, "left"),
            listHeader('Referral', "referral", false, undefined, undefined, undefined, "left"),
            listHeader('Country', 'country', false, (data) => { return this.props.countries ? getSafeDeep(this.props.countries.find(c => c.a2_iso_code == data.country), 'name') : "" }, undefined, undefined, "left"),
            listHeader('Onboarding Date', "created", true, (item) => renderFullDate(item.created), undefined, undefined, "right"),
            listHeader('', "", false, () => { return " " }),
        ]
    }

    toggleInactiveCountries = () => {
        this.setState({ inactiveCountries: !this.state.inactiveCountries })
    }

    toggleUnfinishedOnboarding = () => {
        this.setState({ unfinishedOnboarding: !this.state.unfinishedOnboarding })
    }

    render() {
        const { isLoading, data, count } = this.state;
        return <Fragment>
            <Helmet>
                <title>Onboarding | Elicon</title>
            </Helmet>
            <Page permission={'view_all_onboarding_companies'}>
                <Card>
                    <ListHeader
                        type="Onboarding"
                        title="Onboarding List"
                        searchTitle="Search"
                        openModal={() => {
                            if (this.state.selected.length > 0) {
                                this.setState({ rejectModal: true })
                            }
                        }}
                        toggleInactiveCountries={this.toggleInactiveCountries}
                        toggleUnfinishedOnboarding={this.toggleUnfinishedOnboarding}
                        inactiveCountries={this.state.inactiveCountries}
                        unfinishedOnboarding={this.state.unfinishedOnboarding}
                    />
                    <ListBodyWithLoading
                        sortField={this.state.sort}
                        requestSort={this.requestSort}
                        padding="4px"
                        headers={this.getOnboardingListHeaders()}
                        isLoading={isLoading}
                        data={data}
                        count={count}
                        rowsPerPage={this.props.query.pageSize || 10}
                        onChangeRowsPerPage={this.onChangeRowsPerPage}
                    />
                    <RejectModal
                        toggle={() => {
                            this.setState({ rejectModal: false, selected: [] })
                        }}
                        reloadData={this.loadData}
                        ids={this.state.selected.map(org => { return org.id })}
                        isOpen={this.state.rejectModal}
                    />
                </Card>
            </Page>
        </Fragment>
    };

}

function mapState(state) {
    return {
        query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true }),
        countries: state.countries.data
    };
}

function mapActions(dispatch) {
    return bindActionCreators({ push }, dispatch);
}

export default withRouter(connect(mapState, mapActions)(Onboarding));
