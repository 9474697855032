import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createMessage, notifyError } from "../Util/notification";
import { Form, Text } from 'informed';
import DynamicForm from "../DynamicForm/DynamicForm";
import user from "../../api/user";
import { parseErrors } from "../Util/form";
import dataSchem from "./schema/userData.json";
import uiSchema from "./schema/userUi.json";
import { meetingTypeOptions } from "../Util/meetingTypeOptions";
import { booleanTypes } from "../Source/util";
import { getUserList } from "../../redux/user";
import { getCountryData } from "../Util/countryList";
import rolesApi from '../../api/V3/roles'
import { getSafeDeep } from '../Util/state';

const countries = getCountryData();

class EditUserDialog extends React.Component {

    state = {
        errors: undefined,
        isLoading: false,
        regions: [],
        roles: [],//all roles
        userRoles: []//edit user roles
    };

    componentDidMount() {
        this.loadData()
    }

    loadData = async () => {
        this.setState({ isLoading: true })

        try {
            let { data: roles } = await rolesApi.getAllRoles()
            roles = [{
                group: 'roles',
                actions: roles
            }]
            let { data: regions } = await rolesApi.getRegions()
            this.setState({ roles, regions, isLoading: false });
        } catch (err) {
            this.setState({ isLoading: false })
            notifyError(err.message)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data != this.props.data) {
            this.getData()
        }
    }

    getData = async () => {
        try {
            if (!this.props.data) return
            this.setState({ isLoading: true })
            //user roles
            const { data: rolesData } = await rolesApi.getUserRoles(this.props.data.id)
            const { data: regionData } = await rolesApi.getUserRegion(this.props.data.id)

            if (rolesData.length < 1) {
                await this.setState({ isLoading: false, userRoles: [] })
            }

            await this.setState({
                isLoading: false, userRoles: [{
                    group: 'roles',
                    actions: rolesData.map(role => {
                        return {
                            ...role,
                            region_id: getSafeDeep(regionData, 'region.id')
                        }
                    })
                }]
            })
        } catch (e) {
            notifyError(e.message)
            this.setState({ isLoading: false })
            this.setState({ errors: parseErrors(e) });
        }
    }


    onSubmit = async (data) => {
        try {
            this.setState({ isLoading: true })
            await user.update(this.props.data.id, data);
            if (data.roles[0] && data.roles[0].actions) {
                await rolesApi.assign(this.props.data.id, data.roles[0].actions)
            } else {
                await rolesApi.assign(this.props.data.id, [])
            }
            createMessage('Successfully submitted');
            this.props.getUserList();
            this.props.reloadData();
            this.setState({ isLoading: false })
            this.props.toggle();
        } catch (e) {
            notifyError(e.message)
            this.setState({ isLoading: false })
            this.setState({ errors: parseErrors(e) });
        }
    };

    render() {

        const { toggle, data, tiers } = this.props;
        const { errors } = this.state;

        const modal = {
            isOpen: !!data,
            toggle,
            isLoading: this.state.isLoading,
            title: "Edit User",
            size: "lg"
        }

        return <DynamicForm
            modal={modal}
            uiSchema={uiSchema}
            dataSchema={dataSchem}
            initData={{
                form: {
                    ...data,
                    roles: this.state.userRoles
                },
                roles: this.state.roles,
                regions: this.state.regions
            }}
            errors={errors}
            onSubmit={(data) => { this.onSubmit(data.form) }} />
    };

}

function mapState(state) {
    return {
        tiers: state.tiers.data
    };
}

function mapActions(dispatch) {
    return bindActionCreators({ getUserList }, dispatch);
}

export default withRouter(connect(mapState, mapActions)(EditUserDialog));
