import React, {Fragment} from 'react';
import Steper from '../../Util/New/Stepper/Stepper';
import Body from '../../Util/New/Body/Body';
import Page from '../../UI/Page/Page';
import Card from '../../UI/Card/Card';
import {createMessage, notifyError} from '../../Util/notification';
import {parseErrors} from '../../Util/form';
import {withRouter} from 'react-router-dom';
import company from '../../../api/V3/company';
import {getOrganizationInfoRoute} from '../../Util/routing';
import {deepCopy, getSafeDeep} from '../../Util/state';
import {ERR_VALIDATION} from '../../../api/errorCodes';
import {Helmet} from 'react-helmet';

const steps = (data) => {
  const businessInformationUi = require('../OrganizationMenu/schema/businessInformationUi.json');
  const businessInformationData = require('../OrganizationMenu/schema/businessInformationData.json');

  const bankInfoBusinessModelUi = require('../OrganizationMenu/schema/businessModelUi.json');
  const bankInfoBusinessModelData = require('../OrganizationMenu/schema/businessModelData.json');

  const riskScores = [
    {value: 'low', name: 'Low (0 - 1.49)'},
    {value: 'regular', name: 'Regular (1.5 - 1.99)'},
    {value: 'high', name: 'High (2.0 and over)'}
  ];

  businessInformationUi.elements[1].elements[1].scope.disabled = false; //activate again registration number
  businessInformationUi.elements[2].elements[0].scope.disabled = false; //activate again vat

  bankInfoBusinessModelUi.elements[1].scope.disabled = false; //activate again settlement currency active

  return [
    {
      icon: 'information',
      title: 'Business Information',
      schema: {
        ui: businessInformationUi,
        data: businessInformationData,
        initData: () => ({
          form: {
            primary_address: {},
            country_phone_prefix: getSafeDeep(
              data,
              'form.country_phone_prefix',
              '+386'
            )
          },
          paymentProcessors: [
            {name: 'GoCrypto + Binance', code: 0},
            {name: 'Bitfinex Switzerland', code: 1},
            {name: 'Bitfinex El Salvador', code: 2},
            {name: 'Bitfinex Slovenia', code: 3}
          ],
          risk_scores: riskScores
        })
      }
    },
    {
      icon: 'profile',
      title: 'Contact Information',
      schema: {
        ui: require('../OrganizationMenu/schema/createContactInformationUi.json'),
        data: require('../OrganizationMenu/schema/createContactInformationData.json'),
        initData: () => ({
          form: {
            contact: {
              country_phone_prefix: getSafeDeep(
                data,
                'form.contact.country_phone_prefix',
                '+386'
              )
            }
          }
        })
      }
    },
    {
      icon: 'business',
      title: 'Business Model',
      schema: {
        ui: bankInfoBusinessModelUi,
        data: bankInfoBusinessModelData,
        initData: () => ({form: {bank_info: {}}})
      }
    }
  ];
};

class NewOrganization extends React.Component {
  state = {
    activeStep: 0,
    form: {},
    errors: {},
    isLoading: false
  };

  next = (newForm) => {
    if (this.state.activeStep === steps(this.state.form).length - 1) {
      const data = {
        ...newForm
      };
      this.onSubmit(data);
    } else {
      this.setState((prev) => {
        const data = {
          ...newForm
        };

        /** Set the daily settlement to true if the selected country is El Salvador */
        if (data.form.primary_address) {
          if (data.form.primary_address.country === 'SV') {
            data.form.daily_settlement = true;
          }
        }

        return {
          ...prev,
          form: {
            ...newForm
          },
          activeStep: prev.activeStep + 1
        };
      });
    }
  };

  onSubmit = async (data) => {
    try {
      const formData = deepCopy(data.form);

      this.setState({isLoading: true});
      const contact = formData.contact;
      if (
        contact.country_phone_prefix &&
        contact.country_phone_prefix[0] !== '+'
      ) {
        contact.country_phone_prefix = `+${contact.country_phone_prefix}`;
      }
      if (
        formData.country_phone_prefix &&
        formData.country_phone_prefix[0] !== '+'
      ) {
        formData.country_phone_prefix = `+${formData.country_phone_prefix}`;
      }
      if (contact.send_emails) {
        formData['send_emails'] = contact.send_emails;
      }

      delete formData.contact;

      if (formData.payfac === 'Naka') {
        formData.payfac = null;
      }

      const response = await company.create(formData);
      await company.contacts(response.data.id).create(contact);
      this.setState({isLoading: false});
      createMessage('Successfully created organization');
      this.props.history.replace(getOrganizationInfoRoute(response.data.id));
    } catch (e) {
      const parsedErrors = parseErrors(e);
      this.setState({isLoading: false, activeStep: 0, errors: parsedErrors});
      if (getSafeDeep(e, 'response.data.code', 'NA') === ERR_VALIDATION) {
        const err = JSON.parse(getSafeDeep(e, 'response.data.message', 'NA'));

        let msg = '';

        err.forEach((item) => {
          msg += item.reason + '  ';
        });

        notifyError(msg);
      } else {
        notifyError(`Error occurred: ${e.message}`);
      }
    }
  };

  changeStep = (activeStep, newForm) => {
    if (newForm) {
      this.setState((prev) => ({
        ...prev,
        form: {
          ...newForm
        },
        activeStep
      }));
    } else {
      this.setState({
        activeStep
      });
    }
  };

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>New Company | Elicon</title>
        </Helmet>
        <Page title="New Company">
          <Card>
            <Steper
              steps={steps(this.state.form)}
              changeActive={this.changeStep}
              active={steps(this.state.form)[this.state.activeStep]}
              activeIndex={this.state.activeStep}
            />
            <Body
              country={getSafeDeep(
                this.state.form,
                'form.primary_address.country'
              )}
              activeFiat={getSafeDeep(
                this.state.form,
                'form.fiat_currency_active'
              )}
              isLoading={this.state.isLoading}
              errors={this.state.errors}
              next={this.next}
              stepsCount={steps(this.state.form).length}
              changeActive={this.changeStep}
              activeIndex={this.state.activeStep}
              active={steps(this.state.form)[this.state.activeStep]}
            />
          </Card>
        </Page>
      </Fragment>
    );
  }
}

export default withRouter(NewOrganization);
