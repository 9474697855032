import React, {Fragment, useEffect, useState} from 'react';

import Button from '../../UI/Button/Button';

import './kybDetails.module.css';
import {
  infoField,
  infoFieldCategory,
  renderInfoField
} from '../../Util/infoForm';
import {Col, Row} from 'reactstrap';
import Typography from '@material-ui/core/Typography';
import document_icon from '../document-icon.png';
import {createMessage} from '../../Util/notification';
import companyKyb from '../../../api/companyKyb';
import {getSafeDeep} from '../../Util/state';
import Modal from '../../UI/Modal/Modal';
import Input from '../../UI/Input/Input';
import {toast} from 'react-toastify';

const ownerFields = [
  infoFieldCategory('Personal Information', [
    infoField('first_name', 'First Name'),
    infoField('last_name', 'Last Name'),
    infoField('citizenship', 'Citizenship'),
    infoField('date_of_birth', 'Date of birth', 'date')
  ]),
  infoFieldCategory('Permanent address', [
    infoField('permanent_address.address1', 'Address'),
    infoField('permanent_address.city', 'City'),
    infoField('permanent_address.zipcode', 'Zip code'),
    infoField('permanent_address.country', 'Country', 'country')
  ]),
  infoFieldCategory(
    'Temporary address',
    [
      infoField('temporary_address.address1', 'Address'),
      infoField('temporary_address.city', 'City'),
      infoField('temporary_address.zipcode', 'Zip code'),
      infoField('temporary_address.country', 'Country', 'country')
    ],
    (data) => data.temporary_address
  ),
  infoFieldCategory('', [
    infoField(
      (data) => Math.round(data.ownership_percentage * 100) / 100,
      'Share in company (%)'
    )
  ])
];

const getDocumentType = (data) => {
  if (!data.document) {
    toast.error('The company has no document.');
    return;
  }

  return data.document.type;
};

const representativeFields = [
  infoFieldCategory('Personal Information', [
    infoField(
      'document.issuing_country',
      'Document Issuing Country',
      'country'
    ),
    infoField('document.serial_number', 'Document ID number'),
    infoField('document.issued_date', 'Document Issued Date'),
    infoField('document.expiry_date', 'Document Expiry Date'),
    infoField(
      'document.document_front_image',
      'Document Front image',
      'kycImage'
    ),
    infoField(
      'document.document_back_image',
      'Document Back image',
      'kycImage'
    ),
    infoField(
      (data) => ({1: 'Passport', 0: 'ID Card'}[getDocumentType(data)]),
      'Document type'
    ),
    infoField('first_name', 'First Name'),
    infoField('last_name', 'Last Name'),
    infoField('birth_date', 'Birth date', 'date'),
    infoField('birth_place.city', 'Birth place'),
    infoField('citizenship', 'Citizenship'),
    infoField(
      (data) =>
        Number(getSafeDeep(data, 'ownership_percentage', 0)) * 100 + '%',
      'Ownership Percentage'
    ),
    infoField('document.issuing_authority', 'Issuing Authority')
  ]),
  infoFieldCategory('Permanent address', [
    infoField('permanent_address.address1', 'Address'),
    infoField('permanent_address.city', 'City'),
    infoField('permanent_address.zipcode', 'Zip code'),
    infoField('permanent_address.country', 'Country', 'country')
  ]),
  infoFieldCategory(
    'Temporary address',
    [
      infoField('temporary_address.address1', 'Address'),
      infoField('temporary_address.city', 'City'),
      infoField('temporary_address.zipcode', 'Zip code'),
      infoField('temporary_address.country', 'Country', 'country')
    ],
    (data) => data.temporary_address
  )
];

const RejectModal = ({isOpen, toggle, onRejectClick, isLoading}) => {
  const [reason, setReason] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    setReason('');
    setError('');
  }, [isOpen]);

  const onSubmit = () => {
    if (reason.trim().length === 0) {
      return setError('This field is required');
    }
    onRejectClick(reason);
  };

  return (
    <Modal
      title="Reject Company KYB"
      isOpen={isOpen}
      toggle={toggle}
      isLoading={isLoading}
      submitButton={() => (
        <Button color="danger" isLoading={isLoading} onClick={onSubmit}>
          Reject
        </Button>
      )}
    >
      <div>
        <label htmlFor="reason">Reason</label>
        <Input
          placeholder="Enter reason"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
        <small className="text-danger">{error}</small>
      </div>
    </Modal>
  );
};

const KYBDetails = ({data, dataUpdated}) => {
  const [isLoading, setLoading] = useState(null);
  const [rejectOpen, setRejectOpen] = useState(false);
  const action = async (actionCode, reason) => {
    const actionMap = [
      companyKyb.approve,
      companyKyb.reject,
      companyKyb.setCallMissed
    ];
    const messageMap = [
      'Company Approved',
      'Company Rejected',
      'Call missed status set'
    ];
    setLoading(actionCode);
    try {
      const response = await actionMap[actionCode](data.id, reason);
      createMessage(messageMap[actionCode]);
      getSafeDeep(response.data, 'kyb_data.beneficial_owners', []).forEach(
        (owner) => {
          owner.ownership_percentage *= 100;
        }
      );
      dataUpdated(response.data);
      setRejectOpen(false);
    } catch (e) {
      console.error(e.response);
      if (getSafeDeep(e, 'response.data.code') === 'kybDataError') {
        createMessage(e.response.data.message, 'error');
      } else {
        createMessage('Unexpected error occured', 'error');
      }
    } finally {
      setLoading(null);
    }
  };

  const renderFormData = (item) => {
    if (typeof renderInfoField(item, data.kyb_data) != 'object') {
      return renderInfoField(item, data.kyb_data);
    }

    if (renderInfoField(item, data.kyb_data).props) {
      if (
        renderInfoField(item, data.kyb_data).props.hasOwnProperty('children')
      ) {
        if (!renderInfoField(item, data.kyb_data).props.children) {
          return '/';
        }
      }
      return renderInfoField(item, data.kyb_data);
    }

    return renderInfoField(item, data.kyb_data);
  };

  return (
    <div>
      <Typography variant="h5" className="mb-3">
        Authorized Representative
      </Typography>
      {representativeFields
        .filter((it) => it.visible(data.kyb_data))
        .map((category, i) => (
          <Row key={i}>
            <Col xs={12} className={i ? 'mt-4' : ''}>
              <Typography variant="h6">{category.label}</Typography>
            </Col>
            {category.items.map((item, j) => (
              <Col key={j} sm={6} className="my-2">
                <div className="form-label text-muted">{item.label}</div>
                {renderFormData(item)}
              </Col>
            ))}
          </Row>
        ))}
      {[0, 1].includes(data.business_type) && (
        <Fragment>
          {data.business_type === 1 && (
            <Fragment>
              <Typography variant="h5" className="my-3">
                Company Documents
              </Typography>
              {data.kyb_data.company_documents.map((document) => (
                <a
                  key={document.id}
                  href={document.document_image}
                  className="mr-3 d-inline-block"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    styleName="company-image"
                    width="200"
                    height="200"
                    src={
                      document.document_image.indexOf('.pdf') === -1
                        ? document.document_image
                        : document_icon
                    }
                    alt=""
                  />
                </a>
              ))}
            </Fragment>
          )}
          <Typography variant="h5" className="my-3">
            Beneficial Owners
          </Typography>
          {data.kyb_data.beneficial_owners.map((owner, ownerIndex) => (
            <Fragment key={owner.id}>
              {ownerFields
                .filter((it) => it.visible(owner))
                .map((category, i) => (
                  <Row key={i}>
                    <Col xs={12} className={i ? 'mt-4' : ''}>
                      <Typography variant="h6">{category.label}</Typography>
                    </Col>
                    {category.items.map((item, j) => (
                      <Col key={j} sm={6} className="my-2">
                        <div className="form-label text-muted">
                          {item.label}
                        </div>
                        {renderInfoField(item, owner)}
                      </Col>
                    ))}
                  </Row>
                ))}
              {ownerIndex !== data.kyb_data.beneficial_owners.length && (
                <div styleName="divider" />
              )}
            </Fragment>
          ))}
        </Fragment>
      )}
      <RejectModal
        isOpen={rejectOpen}
        isLoading={isLoading}
        onRejectClick={(reason) => action(1, reason)}
        toggle={() => setRejectOpen(false)}
      />
      <div className="d-flex mt-5">
        {data.kyb_status <= 5 && (
          <>
            <Button
              color="outline-success"
              className="mr-3"
              disabled={isLoading}
              isLoading={isLoading === 0}
              onClick={() => action(0)}
            >
              Approve
            </Button>
            <Button
              color="outline-danger"
              className="mr-3"
              disabled={isLoading}
              onClick={() => setRejectOpen(true)}
            >
              Reject
            </Button>
            <Button
              color="outline-warning"
              disabled={isLoading}
              isLoading={isLoading === 2}
              onClick={() => action(2)}
            >
              Call missed
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default KYBDetails;
