import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ListBody from "../../../../../Util/ListView/ListBody/ListBody";
import WithLoading from "../../../../../Util/HOC/WithLoading";
import qs from "query-string";
import { push } from 'connected-react-router';
import {
    listHeader,
    listHeaders,
    renderCryptoAmount,
    renderEditUser,
    renderFullDate,
    renderName
} from "../../../../../Util/listView";
import { getSafeDeep } from "../../../../../Util/state";
import ListHeader from "../../../../../Util/ListView/ListHeader/ListHeader";
import immutable from 'immutable';

import { Card } from '@material-ui/core';
import { notifyError } from '../../../../../Util/notification';

import withdrawal from "../../../../../../api/V3/withdrawal";
import company from "../../../../../../api/V3/company";
// import WithdrawalDialog from "./WithdrawalDialog";

const ListBodyWithLoading = WithLoading(ListBody);

class Funds extends React.Component {

    state = {
        isLoading: false,
        rowsPerPage: 10,
        sort: '',
        data: [],
        count: 0,
        approveDialogOpen: false,
        declineDialogOpen: false,
        withdrawal: undefined,
    };

    getWithdrawalListHeaders = () => {

        // const localCurrency = this.props.currencies.find(curr => curr.id == getSafeDeep(this.state, "data.0.currency"))
        const localCurrency = this.state.data.map(curr => {
            return this.props.currencies.find(c => c.id == curr.currency)
        }).find(curr => curr.crypto == false);

        return [
            listHeader('Currency', "currency", false, (data) => { return this.props.currencies.find(c => c.id == data.currency).name }),
            listHeader('Amount', "amount", false, (data) => renderCryptoAmount(data.amount, this.props.currencies.find(c => c.id == data.currency).display_name, 8)),
            listHeader(`Amount(${getSafeDeep(localCurrency, "display_symbol")})`, "local_amount", false, data => renderCryptoAmount(data.local_amount, '', 2)),
            listHeader('Withdrawal', "withdrawal", false, (data) => { return data.amount > 0 ? "Available" : "Unavailable" }, undefined, undefined, "right")
        ]
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(oldProps, prevState) {
        const { query, auth } = this.props;
        const { query: oldQuery, auth: oldAuth } = oldProps;
        if (!immutable.is(query, oldQuery) || this.state.rowsPerPage != prevState.rowsPerPage) {
            this.loadData();
        }
    }

    loadData = async () => {
        // let query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        try {
            const query = this.props.query
            const search = query.search;
            let sort = query.sort ? query.sort : "";
            const page = query.page || 0;
            query.sort = sort;
            this.setState({ isLoading: true });
            const { data: data } = await company.getCryptoFunds(this.props.organization.id)
            let count = data.length
            this.setState({ count, data, isLoading: false });
            if (query.page > Math.ceil(count / this.state.rowsPerPage)) query.page = 0;
        } catch (err) {
            this.setState({ isLoading: false })
            notifyError(err.message)
        }
    };

    onChangeRowsPerPage = (e) => {
        let newRowsPerPage = e.target.value;
        let currentItem = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).page * this.state.rowsPerPage || 0 + 1;
        let newPage = Math.floor(currentItem / newRowsPerPage)
        this.setState({ rowsPerPage: newRowsPerPage })
        const query = { ...this.props.query };
        query.page = newPage;
        this.props.history.push(`${this.props.location.pathname}?${qs.stringify(query)}`);
    }

    requestSort = (sort) => {
        this.setState({ sort });
        const query = { ...this.props.query };
        query.page = 0;
        query.sort = sort
        this.props.history.push(`${this.props.location.pathname}?${qs.stringify(query)}`);
    }

    render() {
        const { isLoading, data, rowsPerPage, count } = this.state;

        return <Fragment>
            <Card>
                <ListHeader
                    type="OnlySearch"
                    title="Settlement Funds"
                    searchTitle="Search"
                    noBtn={true}
                    noSearch={true}
                />
                <ListBodyWithLoading
                    sortField={this.state.sort}
                    requestSort={this.requestSort}
                    headers={this.getWithdrawalListHeaders()}
                    isLoading={isLoading}
                    data={data}
                    pagination={true}
                />

            </Card>
        </Fragment>
    };

}

function mapState(state) {
    return {
        query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true }),
        user: state.user.data,
        currencies: state.currencies.data
    };
}

function mapActions(dispatch) {
    return bindActionCreators({ push }, dispatch);
}

export default withRouter(connect(mapState, mapActions)(Funds));
