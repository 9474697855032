import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {createMessage} from "../Util/notification";
import Modal from '../UI/Modal/Modal'
import DynamicForm from "../DynamicForm/DynamicForm";
import user from "../../api/user";
import {ERR_VALIDATION} from "../../api/errorCodes";
import {getSafeDeep} from "../Util/state";
import {mapBackendErrors} from "../DynamicForm/controls/util";
import {parseErrors} from "../Util/form";
import downloadDataSchema from "./schema/downloadData";
import downloadUiSchema from "./schema/downloadUi";
import billing from "../../api/V3/billing";
import moment from "moment";
import externalPosProviders from '../../api/V3/externalPosProviders';


class DownloadDialog extends React.Component {

    state = {
        errors: undefined
    };

    componentDidMount() {

        this.loadSchema();
    }

    componentDidUpdate() {
    }

    onSubmit = async (data) => {
        try {
            const startDate = this.props.start_date.format('YYYY-MM-DD');
            const endDate = this.props.end_date.format('YYYY-MM-DD');

            let downloadData = {...data.form, start_date: startDate, end_date: endDate, [this.props.external ? 'ids' : 'uuids']: this.props.uuids };
            if(this.props.external){
                await externalPosProviders.download(downloadData)
            }else{
                await billing.download(downloadData);
            }
            createMessage("Sent to E-Mail");
            this.props.toggle();
            this.setState({errors: undefined})
        } catch (e) {
            this.setState({ errors: parseErrors(e) });
        }



    };

    loadSchema = async() => {
        const uiSchema = downloadUiSchema;
        const dataSchema = downloadDataSchema;
        this.setState({ uiSchema, dataSchema });
    };



    render() {
        const {toggle, isOpen} = this.props;
        const {errors} = this.state;
        if(!isOpen) return "";

        const toggleAndDeleteErrors = () => {
            this.setState({errors: undefined});
            toggle();
        }

        const modal = {
            toggle: toggleAndDeleteErrors,
            isOpen,
            title: "Download Documents",
            errors,
            size: "md"
        }

        return(
            <DynamicForm    uiSchema={this.state.uiSchema}
                            dataSchema={this.state.dataSchema}
                            initData={{
                                form: {
                                    payment_date: moment().format('YYYY-MM-DD'),
                                    invoice_date: moment().format('YYYY-MM-DD'),
                                }
                            }}
                            modal={modal}
                            errors={errors}
                            onSubmit={(data) => this.onSubmit(data)}
            />
        )
    };
}


export default DownloadDialog;
