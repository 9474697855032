import React from 'react';
import { computeIsRequired, getInputConfig, getInputData, getInputError, getSafe } from "./util";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import * as classnames from "classnames";
import { arrayContextWrapper } from "../ArrayContext";
import { formContextWrapper } from "../FormContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faPlus } from "@fortawesome/free-solid-svg-icons";
import { getBase64 } from "../../Util/image";
import "./base64Image.module.css"
import { getImageS3 } from "../../../api/util";
import ReactImageMagnify from "react-image-magnify";

class Base64KYC extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            required: false,
            $ref: "",
            uploading: false,
            image: undefined
        };

        this.uploadImageRef = React.createRef();
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.scope.$ref !== prevState.$ref || (nextProps.dependsOn && prevState.depValue !== getInputData(nextProps.context, nextProps.array, nextProps.dependsOn))) {
            return {
                required: computeIsRequired(nextProps.context, nextProps.array, nextProps.scope.$ref),
                $ref: nextProps.scope.$ref,
                depValue: nextProps.dependsOn ? getInputData(nextProps.context, nextProps.array, nextProps.dependsOn) : null
            }
        }
        return prevState;
    }

    shouldComponentUpdate(nextProps, nextState) {
        const context = this.props.context;
        const nextContext = nextProps.context;
        const $ref = this.props.scope.$ref;
        const next$ref = nextProps.scope.$ref;
        const array = this.props.array;
        const nextArray = nextProps.array;

        return getInputData(context, array, $ref) !== getInputData(nextContext, nextArray, next$ref) ||
            getInputError(context, array, $ref) !== getInputError(nextContext, nextArray, next$ref) ||
            (this.props.dependsOn && nextProps.dependsOn && getInputData(context, array, this.props.dependsOn) !== getInputData(nextProps.context, nextProps.array, nextProps.dependsOn)) ||
            $ref !== next$ref ||
            context.isLoading !== nextProps.isLoading ||
            this.state.required !== nextState.required
    }

    triggerUploadClick = () => {
        this.uploadImageRef.current.focus();
    };

    componentDidMount() {
        //this.uploadImageRef.current.click();
    }

    componentDidUpdate() {
    }



    onChange = async (e, inputHandler, $ref) => {
        const files = Array.from(e.target.files);
        this.setState({ uploading: true });

        let base64Encoded = await getBase64(files[0]);

        this.setState({
            uploading: false,
            image: base64Encoded
        }, () => {
            inputHandler($ref, this.state.image);
        });



    };

    // <ReactImageMagnify className="mr-3"
    // enlargedImageContainerClassName="large-image-container"
    // styleName="small-image-container" {...{
    //     smallImage: {
    //         isFluidWidth: true,
    //         width: 120,
    //         height: 120,
    //         src: getImageS3(getSafe(value))
    //     },
    //     largeImage: {
    //         src: getImageS3(getSafe(value)),
    //         width: 1200,
    //         height: 1800
    //     }
    // }} />

    renderInput() {
        const { context, scope: { $ref }, readOnly, allowedClear = true, array } = this.props;
        const config = getInputConfig(context, array, $ref);
        const value = getInputData(context, array, $ref);
        const error = getInputError(context, array, $ref);
        const inputHandler = array ? array.onInputChange : context.onInputChange;

        return <div className='kyc-image button d-flex align-items-center'>
            {value &&


                <ReactImageMagnify className="mr-3"
                    enlargedImageContainerClassName="large-image-container"
                    styleName="small-image-container" {...{
                        smallImage: {
                            isFluidWidth: true,
                            width: 120,
                            height: 120,
                            src: getSafe(value)
                        },
                        largeImage: {
                            src: getSafe(value),
                            width: 1200,
                            height: 1800
                        }
                    }}
                />


            }
            <div styleName="image-thumb" className="position-relative">
                <Input disabled={this.props.context.isLoading} ref={this.uploadImageRef} styleName="hidden-input" className=" position-absolute" type='file' id='single' onChange={(e) => this.onChange(e, inputHandler, $ref)} />
                <div styleName="image-thumb bordered" className="d-flex justify-content-center align-items-center">
                    <FontAwesomeIcon icon={faPlus} color="#666" size="lg" />
                </div>
            </div>


        </div>
    }

    render() {

        const { context, scope: { $ref }, label, outterhtmlClass, array } = this.props;
        const error = getInputError(context, array, $ref);
        return (
            <div className={classnames(outterhtmlClass, "mb-3")}>
                <label htmlFor={$ref}>{label}{this.state.required || this.props.required ? <sup className="text-danger">*</sup> : null}</label>
                {this.renderInput()}
                {error && <small className="text-danger">{error}</small>}
            </div>
        );
    };

}

export default arrayContextWrapper(formContextWrapper(Base64KYC));
