import React, {Fragment} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import SideNav from "../SideNav/SideNav";
import {Col, Row} from "reactstrap";
import Button from '../UI/Button/Button'
import ListBody from "../Util/ListView/ListBody/ListBody";
import WithLoading from "../Util/HOC/WithLoading";
import qs from "query-string";
import {push} from 'connected-react-router';
import user from "../../api/user";
import {listHeader, renderCheckBox, renderEditUser, renderLink, renderName} from "../Util/listView";
import {getSafe, getSafeDeep} from "../Util/state";
import ListHeader from "../Util/ListView/ListHeader/ListHeader";
import immutable from 'immutable';
import {routes} from "../Util/routing";
import kyc from "../../api/kyc";
import KYCAactivateDialog from "./KYCActivateDialog";
import KYCEditDialog from "./KYCEditDialog";
import Page from '../UI/Page/Page';
// import { Card } from '@material-ui/core';
import Card from '../UI/Card/Card'
import Select from '../UI/Select/Select'
import { getCountryData, getCountryName } from '../Util/countryList'
import { notifyError } from '../Util/notification';
import {PermissionGuard} from "../Util/role-helpers";
import {Helmet} from "react-helmet";

const countries = getCountryData().map(c => ({
    value: c.code,
    label: c.name
}))

const ListBodyWithLoading = WithLoading(ListBody);

class KYCList extends React.Component {

    kycListHeaders = [
        listHeader('Username', "profile.first_name", false, (item) => renderLink(renderName(item.profile), `${routes.userList}/${item.id}`)),
        listHeader('E-Mail', "email", false),
        listHeader('Tier', "tier.name", false),
        listHeader('Declined', 'declined', false, (item) => renderCheckBox(item.declined)),
        listHeader('', '', false, (item) => <Button color="outline-primary" onClick={() => this.setState({ activateKyc: item })}>Activate</Button>),
        listHeader('','', false, (item) => <PermissionGuard code="update_user_kyc"><Button color="outline-primary" onClick={() => this.setState({ editKyc: item })}>Edit</Button></PermissionGuard>)
    ];

    state = {
        isLoading: true,
        activateKyc: null,
        editKyc: null,
        rowsPerPage: 10,
        count: 0,
        country: null
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(oldProps, prevState) {
        const {query, auth} = this.props;
        const {query: oldQuery, auth: oldAuth} = oldProps;
        if (!immutable.is(query, oldQuery) || this.state.rowsPerPage != prevState.rowsPerPage){
            this.loadData();
        }
    }

    toggleDialog = (dialogName, data = undefined) => {
        const fieldName = `${dialogName}DialogOpen`;
        const fieldState = !this.state[fieldName];
        const fieldDataName = `${dialogName}Data`;
        const fieldData = data !== undefined ? data : this.state[fieldDataName];

        this.setState({ [fieldName]:fieldState, [fieldDataName]:fieldData });
    };

    loadData = async () => {
        try{

            const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
            const page = query.page || 0;
            const country = query.country ? { value: query.country, label: getCountryName(query.country) } : null
            const sort = query.sort;
            this.setState({isLoading: true, country});
            const {data: {count, results: data}} = await kyc.listPaginated((page) * 10, this.state.rowsPerPage, sort, country ? country.value : '');
            this.setState({count, data, isLoading: false});
            if(query.page > Math.ceil(count / this.state.rowsPerPage)) query.page = 0;
        }catch(err){
            this.setState({ isLoading: false })
            notifyError(err.message)
        }
    };

    onChangeRowsPerPage = (e) => {
        let newRowsPerPage = e.target.value;
        let currentItem = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).page * this.state.rowsPerPage || 0 + 1;
        let newPage = Math.floor(currentItem / newRowsPerPage)
        this.setState({ rowsPerPage: newRowsPerPage })
        const query = {...this.props.query};
        query.page = newPage;
        this.props.history.push(`${this.props.location.pathname}?${qs.stringify(query)}`);
    }

    handleCountryFilter= (e)=> {
        this.setState({isLoading: true, country: e});
        const query = {...this.props.query};
        query.country = e.value;
        this.props.history.push(`${this.props.location.pathname}?${qs.stringify(query)}`);
    }

    renderLinks = () => {
        return <div className={"d-flex"}>
            <Button target="_blank" href={"https://namescan.io/"} color="primary">namescan.io</Button>
            <Button className="ml-3" target="_blank" href="http://www.uppd.gov.si/si/javne_objave/seznam_drzav_50_clen_zppdft_1/" color="primary">Country list</Button>
            <div style={{ width: "256px" }}>
                <Select value={this.state.country} onChange={this.handleCountryFilter} className="ml-3" options={countries} />
            </div>
        </div>
    };

    render() {
        const {isLoading, count, rowsPerPage, data, pages, activateKyc, editKyc} = this.state;

        return <Fragment>
            <Helmet>
                <title>KYC List | Elicon</title>
            </Helmet>
            <Page>
                <Card permission={'view_pending_kyc'}>
                    <ListHeader
                        type="KYCAdmin"
                        title = "KYC Admin"
                        searchTitle = "Search"
                        hideSearch = {true}
                        hideAction = {true}
                        additionalComponent = {this.renderLinks}
                        />
                    <ListBodyWithLoading
                        headers={this.kycListHeaders}
                        isLoading={isLoading}
                        data={data}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={this.onChangeRowsPerPage}
                        />
                </Card>
            </Page>
            <KYCAactivateDialog
                isOpen={activateKyc}
                toggle={() => this.setState({ activateKyc: null })}
                reloadData={() => this.loadData()}
                data={activateKyc}
            />
            <KYCEditDialog
                isOpen={!!editKyc}
                data={editKyc}
                toggle={() => this.setState({ editKyc: null })}
                reloadData={() => this.loadData()}
            />
        </Fragment>
    };

}

function mapState(state) {
    return {
        query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true })
    };
}

function mapActions(dispatch) {
    return bindActionCreators({push}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(KYCList));
