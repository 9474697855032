import React from 'react'
import ReactSelect, { components } from "react-select";
import AsyncSelect from "react-select/lib/Async"

import cx from 'classnames'

import './select.module.css'

const Placeholder = props => {
    return <components.Placeholder {...props} />
}

const Select = props => {

    const theme = theme => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary: "#5867dd"
        },
    })

    const styles = {
        option: (provided, state) => {
            if(state.isSelected){
                return{
                    ...provided,
                    fontWeight: 'bold'
                }
            }
            return {
                ...provided
            }
        },
        indicatorSeparator: (provided, state) => {
            return {
                display: 'none'
            }
        },
        dropdownIndicator: (provided, state) => {
            return {
                ...provided,
                position: "relative",
                [":after"]: {
                    position: "absolute",
                    right: "10px",
                    top: "5px",
                    content: '""',
                    ["clip-path"]: "polygon(50% 100%, 0% 0%, 100% 0%)",
                    display: "block",
                    background: "#999",
                    width: "10px",
                    height: "5px",
                },
                [":hover:after"]:{
                    cursor: "pointer",
                    background: '#575962'
                },
                ["svg"]: {
                    display: "none"
                }
            }
        },
        singleValue: () => ({
            color: "#575962!important"
        }),
        placeholder: base => ({
            fontWeight: 300,
            fontFamily: 'Roboto',
            color: "#575962!important"
        })
    }
    if(props.async){
        return (
            <AsyncSelect 
                styles={styles} 
                theme={theme}
                components={{
                    Placeholder,
                    Control: ({innerProps, children}) => (
                        <div
                            styleName={cx('select', {'invalid': !!props.error}, {'loading': props.isLoading})}
                            {...innerProps}>
                            {children}
                        </div>
                    )
                }}
                {...props}  
            />
        )
    }

    return (
        <ReactSelect 
            isClearable={false}
            styles={styles} 
            theme={theme}
            components={{
                Placeholder,
                Control: ({innerProps, children}) => (
                    <div
                        styleName={cx('select', {'invalid': !!props.error})}
                        {...innerProps}>
                        {children}
                    </div>
                )
            }}
            {...props}  
        /> 
    )
}


export default Select;
