import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';

import {connect} from 'react-redux';

import Page from '../../UI/Page/Page';
import Card from '../../UI/Card/Card';
import Stepper from '../../Util/New/Stepper/Stepper';

import organization from '../../../api/organization';

import Body from '../../Util/New/Body/Body';
import AddBrandDialog from '../../Brand/AddBrandDialog';
import stores from '../../../api/V3/stores';
import {deepCopy, getSafeDeep} from '../../Util/state';
import {getTagList} from '../../Source/util';
import {getPosInfoRoute} from '../../Util/routing';
import {Helmet} from 'react-helmet';
import {getCountryCallingCode} from 'libphonenumber-js';
import company from '../../../api/V3/company';
import WithLoading from '../../Util/HOC/WithLoading';
import {parseErrors} from '../../Util/form';
import {ERR_VALIDATION} from '../../../api/errorCodes';
import {notifyError} from '../../Util/notification';
import country from '../../../api/V3/country';

const PageWithLoading = WithLoading(Page);

class NewOfflinePoS extends React.Component {
  state = {
    activeStep: 0,
    addBrandDialogOpen: false,
    form: {},
    errors: {},
    organization: null,
    isLoading: false,
    steps: [
      {
        icon: 'information',
        title: 'Basic Information',
        schema: {
          ui: require('../schema/offline/basicInformationUi.json'),
          data: require('../schema/offline/basicInformationData.json'),
          initData: () => ({form: {}})
        }
      },
      {
        icon: 'profile',
        title: 'Public display',
        schema: {
          ui: require('../schema/offline/presentationUi.json'),
          data: require('../schema/offline/presentationData.json'),
          initData: () => ({form: {}})
        }
      },
      {
        icon: 'business',
        title: 'Bank Information',
        schema: {
          ui: require('../schema/bankInformationUi.json'),
          data: require('../schema/bankInformationData.json'),
          initData: () => ({form: {}})
        }
      },
      {
        icon: 'location',
        title: 'Location & Opening hours',
        schema: {
          ui: require('../schema/offline/locationUi.json'),
          data: require('../schema/offline/locationData.json'),
          initData: () => ({form: {}})
        }
      },
      {
        icon: 'cart',
        title: 'Cashiers',
        schema: {
          ui: require('../schema/offline/newCashiersUi.json'),
          data: require('../schema/offline/newCashiersData.json'),
          initData: () => ({form: {sms_number: {prefix: '386'}}})
        }
      }
    ]
  };

  componentDidMount() {
    this.loadData().catch((error) =>
      console.error('Error loading data: ', error.message)
    );
  }

  loadData = async () => {
    const clone = (prev, id) => {
      return {
        ...prev.steps[id],
        schema: {
          ...prev.steps[id].schema
        }
      };
    };

    this.setState({isLoading: true});
    try {
      const id = this.props.match.params.id;

      const {data: countries} = await country.list();
      const {data: organization} = await company.get(id);
      const {data: transactionFees} = await stores.getTransactionFees(
        organization.primary_address.country,
        'offline'
      );
      const {data: exchangeFees} = await stores.getExchangeFees(
        organization.primary_address.country
      );
      const {data: sourceTimezones} = await stores.getTimezones(
        organization.primary_address.country
      );

      const countryConfig = countries.find(
        (c) => c.a2_iso_code == organization.primary_address.country
      );

      this.setState((prev) => {
        const ret = {
          ...prev,
          organization,
          steps: [...prev.steps],
          isLoading: false
        };

        ret.steps[0] = clone(prev, 0);
        ret.steps[0].schema.initData = (data) => ({
          form: {
            source_type: 0,
            working_hours: getSafeDeep(data, 'form.working_hours', [
              [],
              [],
              [],
              [],
              [],
              [],
              []
            ]),
            country_phone_prefix: getSafeDeep(
              data,
              'form.country_phone_prefix',
              getCountryCallingCode(organization.primary_address.country)
            ),
            country: getSafeDeep(
              data,
              'form.country',
              organization.primary_address.country
            ),
            external_payment_method: false,
            send_emails: false
          }
        });

        ret.steps[1] = clone(prev, 1);
        ret.steps[1].schema.initData = (data) => ({
          form: {
            accepts_goc:
              countryConfig && countryConfig.configuration
                ? countryConfig.configuration.accepted_currencies.includes(6)
                : false,
            viberate_tonight: false,
            accepts_eurt:
              countryConfig && countryConfig.configuration
                ? countryConfig.configuration.accepted_currencies.includes(0) ||
                  countryConfig.configuration.accepted_currencies.includes(10)
                : false,
            language:
              countryConfig &&
              countryConfig.configuration &&
              countryConfig.configuration.default_language
                ? countryConfig.configuration.default_language
                : 'EN',
            currency: this.props.currencies.find((curr) =>
              curr.symbol == countryConfig && countryConfig.configuration
                ? countryConfig.configuration.default_currency
                : ''
            )
              ? this.props.currencies.find((curr) =>
                  curr.symbol == countryConfig && countryConfig.configuration
                    ? countryConfig.configuration.default_currency
                    : ''
                ).id
              : 0,
            public_country_phone_prefix: getSafeDeep(
              data,
              'form.country_phone_prefix',
              getCountryCallingCode(organization.primary_address.country)
            )
          }
        });
        ret.steps[2] = clone(prev, 2);
        ret.steps[2].schema.initData = (data) => {
          return {
            form: {
              transaction_fee_id: getSafeDeep(
                data,
                'transaction_fee_id',
                transactionFees[0].id
              )
            },
            copy: {
              bank_name: organization.bank_name,
              swift: organization.swift,
              TRR: organization.TRR
            },
            transactionFees: transactionFees.map((item) => ({
              label: `${item.value}${item.unit_name}`,
              id: item.id
            })),
            exchangeFees: exchangeFees.map((item) => ({
              label: `${item.value}${item.unit_name}`,
              id: item.id
            })),
            sourceTimezones: sourceTimezones[0][
              this.state.organization.primary_address.country
            ].map((item) => ({
              label: item,
              id: item
            }))
          };
        };
        ret.steps[3] = clone(prev, 3);
        ret.steps[3].schema.initData = (data) => {
          return {
            form: {
              integrator_id: getSafeDeep(data, 'form.integrator_id'),
              buy_currencies: getSafeDeep(data, 'hwPosCryptoBuy'),
              sell_currencies: getSafeDeep(data, 'hwPosCryptoSell')
            }
          };
        };

        return ret;
      });
    } catch (err) {
      notifyError('Something went wrong');
      console.error(err.message);
      this.setState({isLoading: false});
    }
  };

  next = async (newForm) => {
    if (this.state.activeStep === this.state.steps.length - 1) {
      // Create New POS
      try {
        const sourceData = deepCopy(newForm.form);
        sourceData.tags = getTagList(sourceData);
        sourceData.language = sourceData.language.toLowerCase();

        if (sourceData.transaction_fee_id) {
          sourceData.transaction_fee_id = parseInt(
            sourceData.transaction_fee_id
          );
        }
        if (sourceData.exchange_fee) {
          sourceData.exchange_fee = parseInt(sourceData.exchange_fee);
        }
        sourceData.buy_currencies = getSafeDeep(
          sourceData,
          'buy_currencies',
          []
        ).map(({id}) => id);
        sourceData.sell_currencies = getSafeDeep(
          sourceData,
          'sell_currencies',
          []
        ).map(({id}) => id);
        sourceData.sms_number.prefix = `+${sourceData.sms_number.prefix}`;
        if (
          sourceData.national_number === '' ||
          sourceData.national_number == null
        ) {
          sourceData.national_number = null;
          sourceData.country_phone_prefix = null;
        } else {
          sourceData.country_phone_prefix = `+${sourceData.country_phone_prefix}`;
        }
        if (
          sourceData.public_national_number === '' ||
          sourceData.public_national_number == null
        ) {
          sourceData.public_national_number = null;
          sourceData.public_country_phone_prefix = null;
        } else {
          sourceData.public_country_phone_prefix = `+${sourceData.public_country_phone_prefix}`;
        }
        const response = await stores.create(sourceData);
        await organization.link(this.state.organization, response.data);
        this.props.history.push(getPosInfoRoute(response.data.id));
      } catch (e) {
        console.error(e);
        this.setState({
          isLoading: false,
          activeStep: 0,
          errors: parseErrors(e)
        });
        if (getSafeDeep(e, 'response.data.code', 'NA') === ERR_VALIDATION) {
          const err = JSON.parse(getSafeDeep(e, 'response.data.message', 'NA'));
          let msg = '';
          err.forEach((item) => {
            msg += item.reason + '  ';
          });

          notifyError(msg);
        } else {
          notifyError(`Error occurred: ${e.message}`);
        }
      }
    } else {
      this.setState((prev) => ({
        ...prev,
        form: {
          ...newForm
        },
        activeStep: prev.activeStep + 1
      }));
    }
  };

  changeStep = (activeStep, newForm) => {
    if (newForm) {
      this.setState((prev) => ({
        ...prev,
        form: {
          ...newForm
        },
        activeStep
      }));
    } else {
      this.setState({
        activeStep
      });
    }
  };

  toggleDialog = (val) => {
    this.setState({
      addBrandDialogOpen: val
    });
  };

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>New Local Store | Elicon</title>
        </Helmet>
        <PageWithLoading
          noCard
          isLoading={this.state.isLoading}
          title="New Local store"
        >
          <Card>
            <Stepper
              steps={this.state.steps}
              changeActive={this.changeStep}
              active={this.state.steps[this.state.activeStep]}
              activeIndex={this.state.activeStep}
            />
            <Body
              toggleDialog={() => this.toggleDialog(true)}
              isLoading={this.state.isLoading}
              errors={this.state.errors}
              next={this.next}
              storeType="offline"
              organization={this.state.organization}
              changeActive={this.changeStep}
              activeIndex={this.state.activeStep}
              stepsCount={this.state.steps.length}
              formData={this.state.form}
              active={this.state.steps[this.state.activeStep]}
            />
            <AddBrandDialog
              toggle={() => this.toggleDialog(false)}
              isOpen={this.state.addBrandDialogOpen}
              reloadData={() => {}}
            />
          </Card>
        </PageWithLoading>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currencies: state.currencies.data,
    hwPosOptions: state.hwPosOptions.data
  };
};

export default connect(mapStateToProps)(withRouter(NewOfflinePoS));
