import React, {useState} from 'react';
import {Form} from 'reactstrap';

import Input from '../../../UI/Input/Input';

import './styles.module.css';
import Select from '../../../UI/Select/Select';
import * as qs from 'qs';
import Button from '../../../UI/Button/Button';
import cx from 'classnames';
import DatePicker from '../../../UI/DatePicker/DatePicker';

import {getSafeDeep} from '../../state';
import {PermissionGuard} from '../../role-helpers';
import {getCountryData} from '../../countryList';

const extractDate = (data) => {
  let date = '';
  let month;
  let separatedString = data.toString().split(' ');
  switch (separatedString[1]) {
    case 'Jan':
      month = '01';
      break;
    case 'Feb':
      month = '02';
      break;
    case 'Mar':
      month = '03';
      break;
    case 'Apr':
      month = '04';
      break;
    case 'May':
      month = '05';
      break;
    case 'Jun':
      month = '06';
      break;
    case 'Jul':
      month = '07';
      break;
    case 'Aug':
      month = '08';
      break;
    case 'Sep':
      month = '09';
      break;
    case 'Oct':
      month = '10';
      break;
    case 'Nov':
      month = '11';
      break;
    case 'Dec':
      month = '12';
      break;
  }

  date = date.concat(separatedString[3], '-', month, '-', separatedString[2]);

  return date;
};

// Resolves whether the mark action may be performed
const canMark = (uuidList, dataSet) => {
  return (
    uuidList.length > 0 &&
    uuidList.reduce((accumulator, currentUuid) => {
      if (accumulator !== false) {
        return true;
      }
      return accumulator;
    }, true)
  );
};

const countries = getCountryData();

const BillingListHeader = (props) => {
  // const [startDate, setStartDate] = useState(props.startDate);
  // const [endDate, setEndDate] = useState(props.endDate);
  const [search, setSearch] = useState('');

  const updateSearch = (event) => {
    setSearch(event.target.value);
  };

  const query = qs.parse({...props.query}, {ignoreQueryPrefix: true});
  const getOptions = () =>
    countries.map((c) => ({value: c.code, label: c.name}));

  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setCountry,
    selectedList,
    data
  } = props;
  const canMarkBillings = canMark(selectedList, data);

  const getFilter = (key) => query[key];

  const updateSearchQuery = () => {
    const query = {...props.query};

    if (!search || !search.length) {
      delete query.search;
      props.push(`?${qs.stringify(query)}`);
      return;
    }

    query.search = search;
    props.push(`?${qs.stringify(query)}`);
  };
  const submitForm = (event) => {
    event.preventDefault();
    updateSearchQuery();
    props.onSearch(
      extractDate(getSafeDeep(startDate, '_d')),
      extractDate(getSafeDeep(endDate, '_d'))
    );
  };

  return (
    <div>
      <div styleName="header" className="d-flex align-items-center">
        <h4 className="mr-auto">{props.title}</h4>
        <PermissionGuard code="view-billing-overview">
          <Button
            className="mr-2"
            styleName="button-white"
            color="outline-primary"
            onClick={() => props.toggleBilling()}
          >
            Billing Overview
          </Button>
        </PermissionGuard>
        <PermissionGuard code="download-billing-overview">
          <Button
            color="primary"
            styleName={cx({
              'primary-disabled': !props.selected,
              disabled: !props.selected
            })}
            className="mr-2"
            onClick={props.selected ? () => props.toggle() : () => {}}
          >
            Download
          </Button>
        </PermissionGuard>
        <PermissionGuard
          code={
            props.completed
              ? 'delete-payout-payments'
              : 'create-payout-payments'
          }
        >
          <Button
            onClick={canMarkBillings ? () => props.onCompleted() : () => {}}
            styleName={cx(
              'green-button',
              {disabled: !canMarkBillings},
              {'yellow-button': !props.completed}
            )}
          >
            {props.completed ? 'Mark as Completed' : 'Mark as Not Completed'}
          </Button>
        </PermissionGuard>
      </div>
      <div styleName="billing-bottom-header">
        <div styleName="date-pickers">
          <Form onSubmit={submitForm} styleName="search__billing">
            <div className="search-input">
              <Input
                value={search}
                name="search"
                placeholder={props.searchTitle}
                onChange={updateSearch}
              />
              {props.subtitle && (
                <h6 className="ml-5">Default Tenant: {props.subtitle}</h6>
              )}
            </div>
          </Form>

          <div styleName="picker">
            <div styleName="picker-label">Start Date</div>
            <DatePicker
              format="DD/MM/YYYY"
              value={startDate}
              onChange={(data) => setStartDate(data)}
            />
          </div>
          <div styleName="picker">
            <div styleName="picker-label">End Date</div>
            <DatePicker
              format="DD/MM/YYYY"
              value={endDate}
              onChange={(data) => setEndDate(data)}
            />
          </div>
          <div style={{width: '256px'}}>
            <label>Country</label>
            <Select
              value={getOptions().find((c) => c.value == getFilter('country'))}
              placeholder="All Countries"
              options={getOptions()}
              isSearchable={true}
              isClearable={true}
              onChange={(c) => setCountry(c ? c.value : null)}
            />
          </div>
        </div>
        <div className="d-inline-flex">
          <Button className="mr-3" color="outline-primary" onClick={submitForm}>
            Search
          </Button>
          <Button
            target="_blank"
            disabled={getSafeDeep(props, 'selectedList.length') == 0}
            color="primary"
            className="mr-3"
            onClick={() => props.exportCSV()}
          >
            Download
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BillingListHeader;
