import {API_URL} from "../../config";

export function isBase64(object){
    return object.indexOf("data:") == 0;
}

export function appendFormdata(FormData, data, name){
    name = name || '';
    if (typeof data === 'object'){
        Object.keys(data).forEach(function(key){
            if (name == ''){
                appendFormdata(FormData, data[key], key);
            } else {
                appendFormdata(FormData, data[key], name + '['+key+']');
            }
        })
    } else {
        FormData.append(name, data);
    }
}


export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let encoded = reader.result;//.replace(/^data:(.*;base64,)?/, '');///.replace("/","%0a/");
            /*if ((encoded.length % 4) > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
            }*/
            resolve(encoded);
        };
        reader.onerror = error => reject(error);
    });
}
