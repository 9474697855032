import React, { Fragment } from "react";
import hash from "../../api/hash";
import { createMessage, notifyError } from "../Util/notification";
import { parseErrors } from "../Util/form";
import { Button, Col, Form, Input, Row } from "reactstrap";
import Modal from "../UI/Modal/Modal";
import { getSafeDeep } from "../Util/state";
import { changeStateFromInput } from "../Util/controlledComponent";
import DynamicForm from "../DynamicForm/DynamicForm";
import addNotificationDataSchema from "./schema/addNotificationData";
import addNotificationUiSchema from "./schema/addNotificationUi";
import notification from "../../api/notification";
import { otherLanguagesList } from "../Util/language";

class AddNotificationDialog extends React.Component {
  state = {
    errors: undefined,
    quantity: 0,
    isLoading: false,
  };

  componentDidMount() {
    this.loadSchema();
  }

  loadSchema = () => {
    const uiSchema = addNotificationUiSchema;
    const dataSchema = addNotificationDataSchema;
    this.setState({ dataSchema, uiSchema });
  };

  componentDidUpdate() {}

  onSubmit = async (form) => {
      
    const quantity = this.state.quantity;
    try {
      this.setState({ isLoading: true });
      await notification.create(form.form);
      createMessage(`Successfully broadcasted notification`);
      this.props.reloadData();
      this.props.toggle();
      this.setState({ isLoading: false });
    } catch (e) {
      console.error(e);
      notifyError(e.message);
      this.setState({ errors: parseErrors(e), isLoading: false });
    }
  };

  onInput = (e) => {
    this.setState(changeStateFromInput(e));
  };

  render() {
    const { toggle, isOpen } = this.props;
    const { errors } = this.state;

    if (!isOpen) return "";

    const modal = {
      toggle,
      isOpen,
      isLoading: this.state.isLoading,
      title: "Add Broadcast Notification",
      size: "lg",
    };

    return (
      <Fragment>
        <DynamicForm
          uiSchema={this.state.uiSchema}
          dataSchema={this.state.dataSchema}
          errors={this.state.errors}
          modal={modal}
          initData={{
            form: { translations: [] },
            supportedLanguages: otherLanguagesList,
          }}
          onSubmit={(form) => this.onSubmit(form)}
        />
      </Fragment>
    );
  }
}

export default AddNotificationDialog;
