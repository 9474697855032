import React, { Fragment, useState } from 'react'

import { ListItemIcon, ListItemText, withStyles, IconButton } from '@material-ui/core'
import { Done, Close, Search, Delete, GetApp, Send, Autorenew, ImportantDevices, Code } from '@material-ui/icons'

import More from '../../../../UI/More/More'
import Button from '../../../../UI/Button/Button'
import CheckBox from '../../../../UI/CheckBox/CheckBox'
import Modal from '../../../../UI/Modal/Modal'
import Input from '../../../../UI/Input/Input'
import Select from '../../../../UI/Select/Select'

import ListBody from '../../../../Util/ListView/ListBody/ListBody'
import { listHeader, renderFullDate } from '../../../../Util/listView'
import { useEffect } from 'react'
import stores from "../../../../../api/V3/stores";
import { createMessage } from "../../../../Util/notification";
import { deepCopy, getSafeDeep } from "../../../../Util/state";
import cashiers from "../../../../../api/V3/cashiers";
import { API_URL } from "../../../../../config";
import hash from "../../../../../api/V3/hash";
import { has } from "immutable";
import createCashierUi from "../../../schema/createCashierUi";
import createCashierData from "../../../schema/createCashierData";
import editCashierData from "../../../schema/editCashierData";
import DynamicForm from "../../../../DynamicForm/DynamicForm";
import { ERR_VALIDATION } from "../../../../../api/errorCodes";
import { parseErrors } from "../../../../Util/form";
import { mapBackendErrors } from "../../../../DynamicForm/controls/util";
import { hasPermission, PermissionGuard } from "../../../../Util/role-helpers";
import { API_URL_V3 } from "../../../../../api/util";

const StyledListItemText = withStyles({
    root: {
        fontSize: "16px!important"
    }
})(ListItemText)

const StyledIcon = (Icon) => {
    return withStyles({
        root: {
            fontSize: "24px"
        }
    })(Icon)
}

const StyledListItemIcon = withStyles({
    root: {
        minWidth: 24,
        marginRight: "16px"
    }
})(ListItemIcon)

const DeleteModal = (props) => {
    const [loading, setLoading] = useState(false)
    const deleteUser = async () => {
        setLoading(true)
        try {
            await cashiers.delete(props.cashier.id)
            props.toggle()
            createMessage("User deleted")
        } catch (e) {
            createMessage(getSafeDeep(e, "response.data.message", e.message), "error")
        } finally {
            setLoading(false)
        }
    }
    return (
        <Modal title="Are you sure"
            toggle={props.toggle}
            isOpen={!!props.cashier}
            size="xs"
            cancelLabel="Close"
            isLoading={loading}
            submitButton={() => <Button isLoading={loading} className="ml-3" onClick={deleteUser} color="danger">Delete</Button>}>
            <p>Are you sure that you want to delete selected cashier? If selected cashier has existing transaction, it's going to be disabled instead. This action is irreversible!</p>
        </Modal>
    )
}

const ResendInstructionsModal = props => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Modal title="Are you sure"
            isLoading={isLoading}
            toggle={props.toggle}
            isOpen={!!props.cashier}
            size="xs"
            onSubmit={async () => {
                try {
                    setIsLoading(true)
                    await cashiers.resendInstructions(props.cashier.id)
                    props.toggle()
                    setIsLoading(false)
                    createMessage("Instructions sent")
                } catch (e) {
                    setIsLoading(false)
                    createMessage(getSafeDeep(e, "response.data.message", e.message), "error")
                }
            }}
            cancelLabel="Close"
            confirmLabel="Ok" >
            <p>An email with access instructions is going to be send to the point of sale manager at email: <b>{props.cashier && props.cashier.email}</b></p>
        </Modal>
    )
}

const ResetAPIKeyModal = props => {
    const [isLoading, setIsLoading] = useState(false);

    const resetKeys = async () => {
        try {
            setIsLoading(true)
            await cashiers.resetApiKey(props.cashier.id)
            props.toggle()
            setIsLoading(false)
            createMessage("API keys reset")
        } catch (e) {
            setIsLoading(false)
            createMessage(getSafeDeep(e, "response.data.message", e.message), "error")
        }
    }
    return (
        <Modal title="Are you sure"
            toggle={props.toggle}
            isOpen={!!props.cashier}
            isLoading={isLoading}
            size="xs"
            cancelLabel="Close"
            submitButton={() => <Button isLoading={isLoading} className="ml-3" onClick={resetKeys} color="danger">Reset</Button>}
            confirmLabel="Ok" >
            <p>An API key for selected cashier is going to be reset.</p>
        </Modal>
    )
}

const EditQRCodesModal = props => {

    const [release, setRelease] = useState(null)
    const [assign, setAssign] = useState(false)
    const [qrCodes, setQrCodes] = useState([])
    useEffect(() => {
        (async () => {
            if (!props.cashier || assign || release) return
            try {
                const response = await hash.listCodes(0, 100, props.cashier.id)
                setQrCodes(response.data.results)
            } catch (e) {
                createMessage(getSafeDeep(e, "response.data.message", e.message), "error")
            }
        })()
    }, [props.cashier, assign, release])

    const StyledAutoRenew = StyledIcon(Autorenew)

    const listHeaders = [
        listHeader('ID', 'qr_code_id', false),
        listHeader('Value', 'hash', false),
        listHeader('Created', 'created', false, (data) => data.created ? renderFullDate(data.created) : ""),
        ...(hasPermission(props.user, 'release_cashier_qr') ? [listHeader('Actions', 'actions', false, (data) => <IconButton onClick={() => setRelease(data)}><StyledAutoRenew /></IconButton>, undefined, undefined, 'center')] : [])
    ]

    return (
        <Modal title="QR Codes"
            size="lg"
            cancelLabel="Close"
            toggle={props.toggle}
            isOpen={!!props.cashier}
            submitButton={() => { }}
        >
            <div className="d-flex">
                <PermissionGuard code="assign_cashier_qr">
                    <Button className="ml-auto mb-5" onClick={() => setAssign(props.cashier)} color="outline-primary">Assign QR Code</Button>
                </PermissionGuard>
            </div>
            <ListBody
                pagination
                data={qrCodes}
                headers={listHeaders} />
            <ReleaseModal item={release} cashier={props.cashier} toggle={() => setRelease(null)} />
            <AssignModal isOpen={assign} cashier={props.cashier} toggle={() => setAssign(false)} />
        </Modal>
    )
}

const AssignModal = (props) => {

    const loadOptions = async (input) => (await hash.listCodes(0, 100, undefined, input, true)).data.results
    const [selectedHash, setSelectedHash] = useState()
    const [isLoading, setIsLoading] = useState(false);

    const assignCode = async () => {
        try {
            setIsLoading(true)
            await cashiers.assignHash(props.cashier.id, selectedHash.qr_code_id)
            setIsLoading(false)
            props.toggle()
        } catch (e) {
            console.error(e)
            setIsLoading(false)
            createMessage(getSafeDeep(e, "response.data.message", e.message), "error")
        }
    }

    return (
        <Modal
            title="Assign new QR code"
            size="xs"
            isOpen={!!props.isOpen}
            toggle={() => { setSelectedHash(''); props.toggle() }}
            cancelLabel="Close"
            isLoading={isLoading}
            onSubmit={assignCode}
        >
            <label>Search for existing QR code</label>
            <Select
                async
                defaultOptions
                cacheOptions
                value={selectedHash}
                onChange={setSelectedHash}
                getOptionValue={c => c.qr_code_id}
                getOptionLabel={c => c.hash}
                loadOptions={loadOptions}
            />
        </Modal>
    )
}

const AddQRModal = (props) => {

    const loadOptions = async (input) => {
        return (await hash.listCodes(0, 100, undefined, input, true)).data.results
    }

    const [selectedHash, setSelectedHash] = useState();
    const [selectedCashier, setSelectedCashier] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const assignCode = async () => {
        try {
            setIsLoading(true)
            await cashiers.assignHash(selectedCashier.id, selectedHash.qr_code_id)
            setIsLoading(false)
            props.toggle()
        } catch (e) {
            console.error(e)
            setIsLoading(false)
            createMessage(getSafeDeep(e, "response.data.message", e.message), "error")
        }
    }

    return (
        <Modal
            title="Add new QR code"
            size="s"
            isOpen={!!props.isOpen}
            toggle={() => { setSelectedHash(''); props.toggle() }}
            cancelLabel="Close"
            isLoading={isLoading}
            onSubmit={assignCode}
        >
            <label>Select Cashier</label>
            <Select
                async
                defaultOptions={props.cashierList}
                options={props.cashierList}
                cacheOptions
                value={selectedCashier}
                onChange={setSelectedCashier}
                getOptionValue={c => c.id}
                getOptionLabel={c => c.email}
            />
            <br/>
            <label className={"qrLabel"}>Search for existing QR code</label>
            <Select
                async
                defaultOptions
                cacheOptions
                value={selectedHash}
                onChange={setSelectedHash}
                getOptionValue={c => c.qr_code_id}
                getOptionLabel={c => c.hash}
                loadOptions={loadOptions}
            />
        </Modal>
    )
}


const ReleaseModal = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const releaseHash = async () => {
        try {
            setIsLoading(true)
            await cashiers.releaseHash(props.cashier.id, props.item.qr_code_id)
            props.toggle()
            setIsLoading(false)
        } catch (e) {
            console.error(e)
            setIsLoading(false)
            createMessage(getSafeDeep(e, "response.data.message", e.message), "error")
        }
    }
    return (
        <Modal
            size="xs"
            isOpen={!!props.item}
            toggle={props.toggle}
            title="Are you sure?"
            cancelLabel="Close"
            isLoading={isLoading}
            submitButton={() => <Button isLoading={isLoading} className="ml-3" onClick={releaseHash} color="danger">Release</Button>}>
            Selected QR code is going to be released.
        </Modal>
    )
}

class EditCashierModal extends React.Component {

    state = {
        email: '',
        resetPassword: false,
        checked: false,
        isLoading: false,
        errors: {}
    }

    createCashier = async ({ form }) => {
        try {
            this.setState({ isLoading: true })
            const data = deepCopy(form)
            if (data.email === "") {
                delete data.email
            }
            await stores.editCashier(this.props.cashier.id, data)
            this.props.toggle()
        } catch (e) {
            if (getSafeDeep(e, "response.data.code") == ERR_VALIDATION) {
                this.setState({ errors: mapBackendErrors(JSON.parse(e.response.data.message)) })
            } else {
                console.error(e)
                createMessage(getSafeDeep(e, "response.data.message", e.message), "error")
            }
        } finally {
            this.setState({ isLoading: false })
        }
    }

    render() {
        const { errors, isLoading } = this.state;
        const modal = {
            toggle: this.props.toggle,
            isOpen: !!this.props.cashier,
            title: "Create new cashier",
            size: "md",
            confirmLabel: "Submit",
            isLoading
        }
        return (
            <DynamicForm uiSchema={createCashierUi}
                dataSchema={editCashierData}
                modal={modal}
                initData={{
                    form: {
                        is_using_sticker_qr_code: this.props.pos.source_type == 0 ? getSafeDeep(this.props.cashier, 'is_using_sticker_qr_code', false) : null,
                        email: getSafeDeep(this.props.cashier, 'email')
                    },
                    booleanTypes: [
                        { value: true, label: "Yes" },
                        { value: false, label: "No" }
                    ],
                    source_type: this.props.pos.source_type
                }}
                errors={errors}
                onSubmit={this.createCashier} />
        )
    }
}

const ResetPasswordModal = props => {
    const [isLoading, setIsLoading] = useState(false);
    const resetPw = async () => {
        try {
            setIsLoading(true)
            await cashiers.resetPassword(props.cashier.id)
            props.toggle()
            setIsLoading(false)
            createMessage("Password reset")
        } catch (e) {
            setIsLoading(false)
            createMessage(getSafeDeep(e, "response.data.message", e.message), "error")
        }
    }
    return (
        <Modal title="Are you sure"
            toggle={props.toggle}
            isOpen={!!props.cashier}
            isLoading={isLoading}
            size="xs"
            cancelLabel="Close"
            submitButton={() => <Button isLoading={isLoading} className="ml-3" onClick={resetPw} color="danger">Reset</Button>}>
            <p>Password of the cashier is going to be reset.</p>
        </Modal>
    )
}

const ResetPINModal = props => {
    const [isLoading, setIsLoading] = useState(false);
    const resetPIN = async () => {
        try {
            setIsLoading(true)
            await stores.resetPIN(props.pos.id)
            props.toggle()
            setIsLoading(false)
            createMessage("PIN reset")
        } catch (e) {
            setIsLoading(false)
            createMessage(getSafeDeep(e, "response.data.message", e.message), "error")
        }
    }
    return (
        <Modal title="Are you sure"
               toggle={props.toggle}
               isOpen={!!props.cashier}
               isLoading={isLoading}
               size="xs"
               cancelLabel="Close"
               submitButton={() => <Button isLoading={isLoading} className="ml-3" onClick={resetPIN} color="danger">Reset</Button>}>
            <p>PIN of the cashier is going to be reset.</p>
        </Modal>
    )
}

const ResendPINModal = props => {
    const [isLoading, setIsLoading] = useState(false);
    return (
        <Modal title="Are you sure"
               isLoading={isLoading}
               toggle={props.toggle}
               isOpen={!!props.cashier}
               size="xs"
               onSubmit={async () => {
                   try {
                       setIsLoading(true)
                       await stores.resendPIN(props.pos.id)
                       props.toggle()
                       setIsLoading(false)
                       createMessage("PIN has been sent")
                   } catch (e) {
                       setIsLoading(false)
                       createMessage(getSafeDeep(e, "response.data.message", e.message), "error")
                   }
               }}
               cancelLabel="Close"
               confirmLabel="Ok" >
            <p>An email with access instructions is going to be send to the point of sale manager at email: <b>{props.cashier && props.cashier.email}</b></p>
        </Modal>
    )
}

const Cashiers = (props) => {
    const [deleteCashier, setDeleteCashier] = useState(null)
    const [resendInstructionsCashier, setResendInstructionsCashier] = useState(null)
    const [resetAPIKeyCashier, setResetAPIKeyCashier] = useState(null)
    const [resetPasswordOpen, setResetPasswordOpen] = useState(null)
    const [resetPINOpen, setResetPINOpen] = useState(null)
    const [resendPINOpen, setResendPINOpen] = useState(null)
    const [editCashier, setEditCashier] = useState(null)
    const [editQRCodes, setEditQRCodes] = useState(null)
    const [cashierList, setCashierList] = useState([])
    const [newCashier, setNewCashier] = useState(false)
    const [addQRModal, setAddQRModal] = useState(false)

    useEffect(() => {
        (async () => {
            if (deleteCashier || editCashier || newCashier) return
            try {
                const response = await stores.listCashiers(props.pos.id)
                setCashierList(response.data)
            } catch (e) {
                console.error(e)
                createMessage(getSafeDeep(e, "response.data.message", e.message), "error")
            }
        })()
    }, [props.pos, deleteCashier, editCashier, newCashier])

    const renderStickers = (data) => {
        return (
            data ?
                <Done style={{ color: "#34bfa3", fontSize: "24px" }} /> :
                <Close style={{ color: '#f4516c', fontSize: "24px" }} />
        )
    }

    const getActions = (data) => {

        const StyledSearch = StyledIcon(Search)
        const StyledGetApp = StyledIcon(GetApp)
        const StyledSend = StyledIcon(Send)
        const StyledDelete = StyledIcon(Delete)
        const StyledAutorenew = StyledIcon(Autorenew)
        const StyledImportantDevices = StyledIcon(ImportantDevices)
        const StyledCode = StyledIcon(Code)

        const offline = []
        const online = []

        if (hasPermission(props.user, 'change_cashier')) {
            offline.push({
                handler: () => setEditCashier(data),
                render: () => (<Fragment><StyledListItemIcon><StyledSearch /></StyledListItemIcon><StyledListItemText disableTypography primary="Edit Cashier" /></Fragment>)
            })
            online.push({
                handler: () => setEditCashier(data),
                render: () => (<Fragment><StyledListItemIcon><StyledSearch /></StyledListItemIcon><StyledListItemText disableTypography primary="Edit Cashier" /></Fragment>)
            })
        }

        if (hasPermission(props.user, 'view_qr_code_list')) {
            offline.push({
                handler: () => setEditQRCodes(data),
                render: () => (<Fragment><StyledListItemIcon><StyledImportantDevices /></StyledListItemIcon><StyledListItemText disableTypography primary=" Edit QR codes" /></Fragment>)
            })
        }

        if (hasPermission(props.user, 'reset_cashier_api_key')) {
            online.push({
                handler: () => setResetAPIKeyCashier(data),
                render: () => (<Fragment><StyledListItemIcon><StyledCode /></StyledListItemIcon><StyledListItemText disableTypography primary="Reset API key" /></Fragment>)
            })
        }

        if (hasPermission(props.user, 'reset_cashier_api_key')) {
            offline.push({
                handler: () => setResetPasswordOpen(data),
                render: () => (<Fragment><StyledListItemIcon><StyledAutorenew /></StyledListItemIcon><StyledListItemText disableTypography primary="Reset password" /></Fragment>)
            })
            // online.push({
            //     handler: () => setResetPasswordOpen(data),
            //     render: () => (<Fragment><StyledListItemIcon><StyledAutorenew/></StyledListItemIcon><StyledListItemText disableTypography primary="Reset password" /></Fragment> )
            // })
        }

        if (hasPermission(props.user, 'reset_cashier_api_key')) {
            offline.push({
                handler: () => setResetPINOpen(data),
                render: () => (<Fragment><StyledListItemIcon><StyledAutorenew /></StyledListItemIcon><StyledListItemText disableTypography primary="Reset PIN" /></Fragment>)
            })
        }

        if (hasPermission(props.user, 'download_cashier_instructions')) {
            offline.push({
                href: () => `${API_URL_V3}admin/cashiers/${data.id}/download/instructions/`,
                render: () => (<Fragment><StyledListItemIcon><StyledGetApp /></StyledListItemIcon><StyledListItemText disableTypography primary="Download Instructions" /></Fragment>)
            })
            online.push({
                href: () => `${API_URL_V3}admin/cashiers/${data.id}/download/instructions/`,
                render: () => (<Fragment><StyledListItemIcon><StyledGetApp /></StyledListItemIcon><StyledListItemText disableTypography primary="Download Instructions" /></Fragment>)
            })
        }

        if (hasPermission(props.user, 'resend_cashier_instructions') && data.organization_type !== 1) {
            offline.push({
                handler: () => setResendInstructionsCashier(data),
                render: () => (<Fragment><StyledListItemIcon><StyledSend /></StyledListItemIcon><StyledListItemText disableTypography primary="Resend Instructions" /></Fragment>)
            })
            online.push({
                handler: () => setResendInstructionsCashier(data),
                render: () => (<Fragment><StyledListItemIcon><StyledSend /></StyledListItemIcon><StyledListItemText disableTypography primary="Resend Instructions" /></Fragment>)
            })
        }

        if (hasPermission(props.user, 'reset_cashier_api_key')) {
            offline.push({
                handler: () => setResendPINOpen(data),
                render: () => (<Fragment><StyledListItemIcon><StyledSend /></StyledListItemIcon><StyledListItemText disableTypography primary="Resend PIN" /></Fragment>)
            })
        }

        if (hasPermission(props.user, 'delete_cashier')) {
            offline.push({
                handler: () => setDeleteCashier(data),
                render: () => (<Fragment><StyledListItemIcon><StyledDelete /></StyledListItemIcon><StyledListItemText disableTypography primary="Delete" /></Fragment>)
            })
            online.push({
                handler: () => setDeleteCashier(data),
                render: () => (<Fragment><StyledListItemIcon><StyledDelete /></StyledListItemIcon><StyledListItemText disableTypography primary="Delete" /></Fragment>)
            })
        }

        const handlers = [
            {//offline
                items: offline
            },
            {//online
                items: online
            }
        ]

        return handlers[props.pos.source_type]
    }

    const listHeaders = [
        [//offline
            listHeader('Email', 'email', true),
            listHeader('Sticker QR codes?', 'is_using_sticker_qr_code', true, (data) => renderStickers(data.is_using_sticker_qr_code), undefined, undefined, 'center'),
            listHeader('# QR codes', 'qr_count', true, undefined, undefined, undefined, 'center'),
            listHeader('Last sent Access Instruction', 'access_instructions_last_sent_at', true, (data) => data.access_instructions_last_sent_at ? renderFullDate(data.access_instructions_last_sent_at) : "Never"),
            listHeader('Actions', 'actions', false, (data) => <More {...getActions(data)} />, undefined, undefined, "center"),
        ],
        [//online
            listHeader('Email', 'email', true),
            listHeader('Last sent Access Instruction', 'access_instructions_last_sent_at', true, (data) => data.access_instructions_last_sent_at ? renderFullDate(data.access_instructions_last_sent_at) : "Never"),
            listHeader('Actions', 'actions', false, (data) => <More {...getActions(data)} />, undefined, undefined, "center")
        ]
    ]
    const modal = {
        toggle: () => setNewCashier(false),
        isOpen: newCashier,
        title: "Create new cashier",
        size: "md",
        confirmLabel: "Submit"
    }

    const [createCashierLoading, setCreateCashierLoading] = useState(false)
    const [newCashierErrors, setNewCashierErrors] = useState({})

    const createCashier = async ({ form }) => {
        try {
            setCreateCashierLoading(true)
            const data = deepCopy(form)
            if (data.email === "") {
                delete data.email
            }
            await stores.createCashier(props.pos.id, data)
            setNewCashier(false)
        } catch (e) {
            if (getSafeDeep(e, "response.data.code") == ERR_VALIDATION) {
                setNewCashierErrors(mapBackendErrors(JSON.parse(e.response.data.message)))
            } else {
                console.error(e)
                createMessage(getSafeDeep(e, "response.data.message", e.message), "error")
            }
        } finally {
            setCreateCashierLoading(false)
        }
    }

    return (
        <div>
            <div className="d-flex align-items-center">
                <h3 style={{ color: "#7B7D8A" }} className="py-5" >Cashier List</h3>
                <PermissionGuard code="create_cashier">
                    <Button className="ml-auto" type="button" onClick={(e) => setNewCashier(true)} color="outline-primary">Add</Button>
                </PermissionGuard>
                <PermissionGuard code="create_cashier">
                    <Button className="ml-auto" type="button" onClick={(e) => setAddQRModal(true)} color="outline-primary">Add QR Code</Button>
                </PermissionGuard>
            </div>
            <div className="pb-5">
                <ListBody
                    pagination
                    data={cashierList}
                    headers={listHeaders[props.pos.source_type]} />
            </div>
            <DeleteModal cashier={deleteCashier} toggle={() => setDeleteCashier(null)} />
            <ResendInstructionsModal cashier={resendInstructionsCashier} toggle={() => setResendInstructionsCashier(null)} />
            <ResetPasswordModal cashier={resetPasswordOpen} toggle={() => setResetPasswordOpen(null)} />
            <ResetPINModal pos={props.pos} cashier={resetPINOpen} toggle={() => setResetPINOpen(null)}/>
            <ResendPINModal pos={props.pos} cashier={resendPINOpen} toggle={() => setResendPINOpen(null)}/>
            <ResetAPIKeyModal cashier={resetAPIKeyCashier} toggle={() => setResetAPIKeyCashier(null)} />
            <EditCashierModal pos={props.pos} cashier={editCashier} toggle={() => setEditCashier(null)} />
            <EditQRCodesModal cashier={editQRCodes} toggle={() => setEditQRCodes(null)} user={props.user} />
            <AddQRModal cashierList={cashierList} toggle={() => setAddQRModal(null)} isOpen={addQRModal} />
            <DynamicForm uiSchema={createCashierUi}
                dataSchema={createCashierData}
                readOnly={!hasPermission(props.user, 'change_source_v3')}
                modal={modal}
                initData={{
                    form: {
                        is_using_sticker_qr_code: false
                    },
                    booleanTypes: [
                        { value: true, label: "Yes" },
                        { value: false, label: "No" }
                    ],
                    source_type: props.pos.source_type
                }}
                errors={newCashierErrors}
                isLoading={createCashierLoading}
                onSubmit={createCashier} />
        </div>
    )
}

export default Cashiers;
