import WithLoading from "../Util/HOC/WithLoading";
import ListBody from "../Util/ListView/ListBody/ListBody";
import React, {Fragment} from "react";
import {getSafeDeep} from "../Util/state";
import qs from "query-string";
import {listHeader, renderCreatedAt} from "../Util/listView";
import Page from "../UI/Page/Page";
import {Card} from "@material-ui/core";
import ListHeader from "../Util/ListView/ListHeader/ListHeader";
import {bindActionCreators} from "redux";
import {push} from "connected-react-router";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Modal from '../UI/Modal/Modal';
import "./ActivitiesModal.module.css";
import referral from "../../api/V3/referral";
import { notifyError } from "../Util/notification";


const ListBodyWithLoading = WithLoading(ListBody);

const statuses = [
    'STARTED',
    "FINISHED",
    'EXTRA'
]

const Badge = (props) => {
    const styles = {
        color: "#fff",
        background: "#5867dd",
        display: 'inline-block',
        padding: '.25em .4em',
        fontSize: '75%',
        fontWeight: 700,
        lineHeight: 1,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        verticalAlign: 'baseline',
        borderRadius: '.25rem'
    }
    return <div style={styles}>{props.children}</div>
}

class ActivitiesModal extends React.Component {
    state = {
        data: {},
        count: 0,
        page: 0,
        rowsPerPage: 10,
        sort: '',
        isLoading: true,
        BillingOverviewOpen: false,
        referralData: {},
    }

    componentDidUpdate(oldProps, prevState) {
        if(this.props.id && oldProps.id !== this.props.id 
        || this.state.rowsPerPage != prevState.rowsPerPage
        || this.state.page != prevState.page)
            this.loadData();
    }


    loadData = async () => {
        try{
            this.setState({isLoading: true});

            let { page, rowsPerPage, sort } = this.state
            if(page > 0 && !this.state.data.length){//page more than 0 and no data
                page = 0;
            }

            const referralData = (await referral.getReferral(this.props.id)).data;
            const {data: {count, results: data}} = await referral.getReferralActivities(this.props.id, page * this.state.rowsPerPage, this.state.rowsPerPage, sort);

            this.setState({
                data,
                count,
                referralData,
                isLoading: false,
            });
        }catch(err){
            notifyError(err.message)
            this.setState({ isLoading: false })   
        }

    };



    overviewHeaders = [
        listHeader('ReferralID', "referral__id", true, ({referral: {id}}) => id ),
        listHeader('Owner', "referral__owner", true,({referral: {owner}}) => owner, undefined, undefined, "center"),
        listHeader('Onboarding status', "onboarding_status ", false, ({onboarding_status}) => { return <Badge>{statuses[onboarding_status]}</Badge>}, undefined, undefined, "center"),
        listHeader('Onboarding company', "company_name", false, undefined, undefined, "undefined", "center" ),
        listHeader('Created at', "created_at", true, row => renderCreatedAt(row), undefined, "undefined", "right" ),
    ];


    onChangeRowsPerPage = (e) => {
        let newRowsPerPage = e.target.value;
        let currentItem = this.state.page * this.state.rowsPerPage || 0 + 1;
        let newPage = Math.floor(currentItem / newRowsPerPage)
        this.setState({ rowsPerPage: newRowsPerPage, page: newPage }, () => {
            this.loadData()
        })
    };

    changePage = (e, page) => {
        this.setState({ page })
    }

    requestSort = (sort) => {
        this.setState({sort, page: 0}, () => {
            this.loadData()
        });
    }

    render() {

        const {referralData, isLoading, rowsPerPage} = this.state;
        if(this.state.referralData !== {}){
            return <Modal isOpen={this.props.isOpen} toggle={() => this.props.toggle()} submitButton={() => null} size="lg" title="Referral activities" >

                    <div styleName="container">
                        <div styleName="item">
                            <div styleName="title">REFERRAL ID</div>
                            <div styleName="label">{this.state.referralData.id}</div>
                        </div>
                        <div styleName="item">
                            <div styleName="title">TARGET URL</div>
                            {/* <div styleName="label"><a href={referralData.target_url} target={"_blank"}>{referralData.target_url}</a></div> */}
                            <div styleName="label"><code styleName="code">{referralData.target_url}</code></div>
                        </div>
                        <div styleName="item">
                            <div styleName="title">OWNER</div>
                            <div styleName="label">{referralData.owner}</div>
                        </div>
                        <div styleName="item">
                            <div styleName="title">SHORT URL</div>
                            {/* <div styleName="label"><a href={referralData.short_url} target={"_blank"}>{referralData.short_url}</a></div> */}
                            <div styleName="label"><code styleName="code">{referralData.short_url}</code></div>
                        </div>
                        <div styleName="item">
                            <div styleName="title">CREATED AT</div>
                            <div styleName="label">{referralData.created_at && renderCreatedAt(referralData.created_at)}</div>
                        </div>
                        <div styleName="item note">
                            <div styleName="title">Note</div>
                            <div styleName="label">{referralData.note}</div>
                        </div>

                    </div>

                    <ListHeader
                        type="ReferralAction"
                    />
                    <ListBodyWithLoading
                        sortField={this.state.sort}
                        requestSort={this.requestSort}
                        headers={this.overviewHeaders}
                        isLoading={isLoading}
                        data={this.state.data}
                        count={this.state.count}
                        currentPage={this.state.page}
                        onChangePage={this.changePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={this.onChangeRowsPerPage}
                    />
            </Modal>
        }else
            return null
    };

}


function mapState(state) {
    return {
        query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true })
    };
}

function mapActions(dispatch) {
    return bindActionCreators({push}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(ActivitiesModal));
