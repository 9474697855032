import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import qs from 'query-string'
import exportApi from '../../../api/V3/export'

import LinkPointOfSaleDialog from '../Others/LinkPointOfSaleDialog'

import { getPosInfoRoute } from '../../Util/routing'
import ListHeader from '../../Util/ListView/ListHeader/ListHeader'
import ListBody from '../../Util/ListView/ListBody/ListBody'
import WithLoading from '../../Util/HOC/WithLoading'
import {
    listHeader,
    renderActivityStatus,
    renderCheckmark,
    renderDoneClear,
    renderFullDate,
    renderLink
} from '../../Util/listView'

import Page from '../../UI/Page/Page'
import Card from '../../UI/Card/Card'
import More from '../../UI/More/More'

import { ListItemIcon, ListItemText } from '@material-ui/core';
import { Link, Search } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles';
import stores from "../../../api/V3/stores";
import { createMessage } from "../../Util/notification";
import { getSafeDeep } from "../../Util/state";
import { getCountries } from "libphonenumber-js";
import { getCountryName } from "../../Util/countryList";
import platforms from "../../../redux/platform";
import posProviders from "../../../redux/posProvider";
import exportCSVUi from "../schema/exportCSVUi";
import exportCSVData from "../schema/exportCSVData";
import DynamicForm from "../../DynamicForm/DynamicForm";
import moment from "moment";
import { hasPermission } from "../../Util/role-helpers";
import { Helmet } from "react-helmet";

const ListBodyWithLoading = WithLoading(ListBody);

export const approvalStates = [
    'Pending',
    'Approved',
    'Rejected',
    'Terminated'
]

class PoSList extends Component {

    state = {
        count: 0,
        isLoading: true,
        selected: null,
        data: [],
        exportOpen: false,
        exportInProgress: false
    }

    getActions = (data) => {

        const StyledListItemText = withStyles({
            root: {
                fontSize: "16px!important"
            }
        })(ListItemText)

        const StyledIcon = (Icon) => {
            return withStyles({
                root: {
                    fontSize: "24px"
                }
            })(Icon)
        }

        const StyledListItemIcon = withStyles({
            root: {
                minWidth: 24,
                marginRight: "16px"
            }
        })(ListItemIcon)

        const StyledSearch = StyledIcon(Search)
        const StyledLink = StyledIcon(Link)

        const items = [{
            handler: () => { this.props.history.push(getPosInfoRoute(data.id)) },
            render: () => (<Fragment><StyledListItemIcon><StyledSearch /></StyledListItemIcon><StyledListItemText disableTypography primary="Details" /></Fragment>)
        }]

        if (hasPermission(this.props.user, 'company_add_source')) {
            items.push({
                handler: () => this.toggleLink(data),
                render: () => (<Fragment><StyledListItemIcon><StyledLink /></StyledListItemIcon><StyledListItemText disableTypography primary="Link" /></Fragment>)
            })
        }

        return {
            items
        }
    }

    componentDidMount() {
        this.loadData()
    }

    componentDidUpdate(prevProps, prevState): void {
        if (JSON.stringify({ ...prevProps.query, rows: prevState.rowsPerPage }) !==
            JSON.stringify({ ...this.props.query, rows: this.state.rowsPerPage })) {
            this.loadData()
        }
    }


    loadData = async () => {
        let { page, search, sort, pageSize, ...filter } = { ...this.props.query }
        this.setState({ isLoading: true })
        try {
            const response = await stores.list((pageSize || 10), (page || 0) * (pageSize || 10), search, sort, filter)
            this.setState({
                count: response.data.count,
                data: response.data.results
            })
        } catch (e) {
            createMessage(getSafeDeep(e, "response.data.message", getSafeDeep(e, "response.data", e.message)), 'error')
        } finally {
            this.setState({ isLoading: false })
        }
    }


    toggleLink = (pos) => {
        this.setState(prev => ({
            isOpen: !prev.isOpen,
            selected: pos
        }))
    }
    listHeaders = [
        listHeader('Name', 'full_name', true, (item) => renderLink(item.full_name, getPosInfoRoute(item.id))),
        listHeader('Brand', 'franchise__name', true, (item) => getSafeDeep(item, 'franchise.name', 'N\\A')),
        listHeader('Company', 'organizations__full_name', true, (item) => getSafeDeep(item, 'company.0.full_name', 'N\\A')),
        listHeader('Store type', "source_type", false, (data) => { return renderDoneClear(!!data.source_type) }, undefined, undefined, 'center'),
        listHeader('City', "city", true),
        listHeader('Country', "country", true, (data) => {
            const country = data.country || getSafeDeep(data, 'company.0.country')
            if (country) {
                return getCountryName(country)
            }
            return ""
        }),
        listHeader('Referral', 'organizations__referrals__owner', true, (data) => getSafeDeep(data, 'company.0.referrals.0.owner', "None")),
        listHeader('Payment Platform', "integrator__name", true, (data) => getSafeDeep(this.props.providers.find(it => it.id === data.integrator_id), 'name', "")),
        listHeader('Created', "created", true, (data) => renderFullDate(data.created)),
        listHeader('Status', "approval_state", false, (data) => approvalStates[data.approval_state]),
        listHeader('Activity Status', "activity_status", false, (data) => renderActivityStatus(data.activity_status)),
        listHeader('Actions', 'actions', false, (data) => (<More {...this.getActions(data)} />), undefined, undefined, "center")
    ]

    onChangeRowsPerPage = (e) => {
        let newRowsPerPage = e.target.value;
        const query = { ...this.props.query }
        let currentItem = query.page * query.pageSize || 0;
        query.page = Math.floor(currentItem / newRowsPerPage);
        query.pageSize = newRowsPerPage;
        this.props.history.push(`${this.props.location.pathname}?${qs.stringify(query)}`);
    }

    onSortChange = (field) => {
        const query = { ...this.props.query }
        query.sort = field
        this.props.history.push(`${this.props.location.pathname}?${qs.stringify(query)}`)
    }

    onExport = async () => {
        this.setState({ exportInProgress: true })
        try {
            let { search, sort, created_from, created_to, ...filter } = { ...this.props.query }

            const response = await exportApi.exportSourceList(search, sort, {
                ...filter,
                date_from: created_from,
                date_to: created_to
            })
            const href = URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('A');
            a.href = href;
            a.download = "store-list-export.csv";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(href)
            this.setState({ exportOpen: false })
        } catch (e) {
            console.error(e)
            createMessage(getSafeDeep(e, "response.data.message", getSafeDeep(e, "response.data", e.message)), 'error')
        } finally {
            this.setState({ exportInProgress: false })
        }
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Stores | Elicon</title>
                </Helmet>
                <Page permission={'view_all_sources_v3'}>
                    <Card>
                        <ListHeader
                            type="PoSList"
                            title="Points of Sale List"
                            searchTitle="Search"
                            exportLoading={this.state.exportInProgress}
                            onExportClicked={this.onExport}
                        />
                        <ListBodyWithLoading
                            isLoading={this.state.isLoading}
                            headers={this.listHeaders}
                            data={this.state.data}
                            count={this.state.count}
                            rowsPerPage={parseInt(this.props.query.pageSize || 10)}
                            requestSort={this.onSortChange}
                            sortField={this.props.query.sort}
                            onChangeRowsPerPage={this.onChangeRowsPerPage}
                        />
                        <LinkPointOfSaleDialog
                            source={this.state.selected}
                            toggle={this.toggleLink}
                            isOpen={this.state.isOpen} />
                    </Card>
                </Page>
            </Fragment>
        );
    }
}

function mapState(state) {
    return {
        query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true }),
        providers: [
            ...state.posProviders.data,
            ...state.platforms.data
        ],
        sidebar: state.sidebar,
        user: state.user.data
    };
}

function mapActions(dispatch) {
    return bindActionCreators({ push }, dispatch);
}

export default withRouter(connect(mapState, mapActions)(PoSList));
