import React, {Fragment} from 'react';
import {computeIsRequired, getInputConfig, getInputData, getInputError, getSafe} from "./util";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import * as classnames from "classnames";
import {arrayContextWrapper} from "../ArrayContext";
import {formContextWrapper} from "../FormContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCross, faImage, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {getBase64} from "../../Util/image";
import "./base64Image.module.css"
import source from "../../../api/source";
import {getImageS3} from "../../../api/util";

class Image extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            required: false,
            $ref: "",
            uploading: false,
            imageUrl: undefined
        };

        this.uploadImageRef = React.createRef();
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.scope.$ref !== prevState.$ref || (nextProps.dependsOn && prevState.depValue !== getInputData(nextProps.context, nextProps.array, nextProps.dependsOn))) {
            return {
                required: computeIsRequired(nextProps.context, nextProps.array, nextProps.scope.$ref),
                $ref: nextProps.scope.$ref,
                depValue: nextProps.dependsOn ? getInputData(nextProps.context, nextProps.array, nextProps.dependsOn) : null
            }
        }
        return prevState;
    }

    shouldComponentUpdate(nextProps, nextState) {
        const context = this.props.context;
        const nextContext = nextProps.context;
        const $ref = this.props.scope.$ref;
        const next$ref = nextProps.scope.$ref;
        const array = this.props.array;        
        const nextArray = nextProps.array;
        const required = this.state.required;
        const nextRequired = computeIsRequired(nextProps.context, nextProps.array, nextProps.scope.$ref);
        return getInputData(context, array, $ref) !== getInputData(nextContext, nextArray, next$ref) ||
            getInputError(context, array, $ref) !== getInputError(nextContext, nextArray, next$ref) ||
            (this.props.dependsOn && nextProps.dependsOn && getInputData(context, array, this.props.dependsOn) !== getInputData(nextProps.context, nextProps.array, nextProps.dependsOn)) ||
            required !== nextRequired ||
            $ref !== next$ref
    }

    componentDidMount() {
        //this.uploadImageRef.current.click();
    }

    componentDidUpdate() {
    }


    onDelete = (inputHandler, $ref) => {

        this.setState({image: undefined}, async () => {
            await inputHandler($ref, undefined);
        });

    };



    onChange = async (e, inputHandler, $ref) => {
        const files = Array.from(e.target.files);
        this.setState({ uploading: true });

        const formData = new FormData();
        formData.append('image', files[0]);

        try {
            const response = await source.uploadImage(formData);
            this.setState({
                image: response.data.image,
                uploading: false
            }, () => {
                inputHandler($ref, this.state.image);
            })
        } catch (e) {

        }




    };

    renderInput(){
        const {context, scope: {$ref}, readOnly, allowedClear = true, array} = this.props;
        const {loading} = this.state;
        const config = getInputConfig(context, array, $ref);
        const value = getInputData(context, array, $ref);
        const error = getInputError(context, array, $ref);
        const inputHandler = array ? array.onInputChange : context.onInputChange;


        return <div className='button d-flex align-items-center'>
            {value ?
                (<div styleName="image-thumb" className="position-relative mr-3">
                    <img styleName="image-thumb" className="mr-3" alt="image field input" src={getSafe(value)}/>
                    <div styleName="cross" className="position-absolute" onClick={(e) => this.onDelete(inputHandler, $ref)}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </div>
                </div>)
            : 
                (<div styleName="image-thumb upload-wrapper" className="position-relative">
                    <Input disabled={this.props.disabled} ref={this.uploadImageRef} styleName="hidden-input" className=" position-absolute" type='file' id='single' onChange={(e) => this.onChange(e, inputHandler, $ref)} />
                    <div styleName="image-thumb bordered" className="d-flex justify-content-center align-items-center">
                        <FontAwesomeIcon icon={faPlus} color="#bbbbbb" size="2x"/>
                    </div>
                </div>)
            }
            </div>
            // <div styleName="image-thumb upload-wrapper" className="position-relative">
            //     <div styleName="image-thumb bordered" className="d-flex justify-content-center align-items-center">
            //         <FontAwesomeIcon icon={faCross} color="#bbbbbb" size="2x"/>
            //     </div>
            // </div>
    }

    render() {

        const {context, scope: {$ref}, label, outterhtmlClass, array} = this.props;
        const error = getInputError(context, array, $ref);


        return (
            <div className={classnames(outterhtmlClass, "mb-3")}>
                <label htmlFor={$ref}>{label}{this.state.required ? <sup className="text-danger">*</sup> : null}</label>
                {this.renderInput()}
                {error && <small className="text-danger">{error}</small>}
            </div>
        )


        return (
            <FormGroup className={classnames(outterhtmlClass,"row")}>
                <Label className="col-3 align-items-center d-flex m-0" htmlFor={$ref}>{label}{this.state.required && <sup className="text-danger">*</sup>}</Label>
                <div className="col-9">
                    <div className='buttons fadein'>
                        {this.renderInput()}
                    </div>
                </div>
                <div className="col-9 offset-3">
                    <FormFeedback valid={!error} className={classnames({"d-block": !!error})}>{error}</FormFeedback>
                </div>
            </FormGroup>
        );
    };

}

export default arrayContextWrapper(formContextWrapper(Image));
