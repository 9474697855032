import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import SideNav from "../SideNav/SideNav";
import {Col, Row} from "reactstrap";
import ListBody from "../Util/ListView/ListBody/ListBody";
import WithLoading from "../Util/HOC/WithLoading";
import qs from "query-string";
import {push} from 'connected-react-router';
import user from "../../api/user";
import {
    listFilter,
    listHeader,
    renderAccountantName,
    renderHash,
    renderBundle, renderCreated,
    renderCreatedAt,
    renderCreatedBy,
    renderEditUser,
    renderName, renderSource
} from "../Util/listView";
import {getSafe, getSafeDeep} from "../Util/state";
import ListHeader from "../Util/ListView/ListHeader/ListHeader";
import immutable from 'immutable';
import organization from "../../api/organization";
import hash from "../../api/hash";
import v3hash from "../../api/V3/hash";

import Page from '../UI/Page/Page';
import { Card } from '@material-ui/core';
import { notifyError } from '../Util/notification';
import {Helmet} from "react-helmet";



const QRCodeListHeaders = [
    listHeader('Hash', "hash", false, renderHash),
    listHeader('Source name', "sources", false, renderSource),
    listHeader('Connected user', "accountant.email", false),
    listHeader('Created on', "created", false, renderCreated),
];

const QRCodeListFilters = [
    listFilter('Unassigned Only', 'unassigned', true),
    listFilter('All', 'unassigned', false),
];


const ListBodyWithLoading = WithLoading(ListBody);

class QRCodeList extends React.Component {

    state = {
        isLoading: false,
        addBundleDialogOpne: false,
        rowsPerPage: 10,
        count: 0,
        data: []
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(oldProps, prevState) {
        const {query, auth} = this.props;
        const {query: oldQuery, auth: oldAuth} = oldProps;
        if (!immutable.is(query, oldQuery) || this.state.rowsPerPage != prevState.rowsPerPage){
            this.loadData();
        }
    }

    toggleDialog = (dialogName) => {
        const fieldName = `${dialogName}DialogOpen`;
        this.setState({ [fieldName]:![fieldName] });
    };

    loadData = async () => {
        try{
            // const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
            const query = this.props.query
            const page = query.page || 0;
            const search = query.search;
            const unassigned = query.unassigned;
            this.setState({isLoading: true});
            const {data: {count, results: data}} = await v3hash.listPaginated((page) * this.state.rowsPerPage, this.state.rowsPerPage, unassigned, search);
            this.setState({count, data, isLoading: false});
            if(query.page > Math.ceil(count / this.state.rowsPerPage)) query.page = 0;
        }catch(err){
            this.setState({isLoading: false});
            notifyError(err.message)
        }
    };

    onChangeRowsPerPage = (e) => {
        let newRowsPerPage = e.target.value;
        let currentItem = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).page * this.state.rowsPerPage || 0 + 1;
        let newPage = Math.floor(currentItem / newRowsPerPage)
        this.setState({ rowsPerPage: newRowsPerPage })
        const query = {...this.props.query};
        query.page = newPage;
        this.props.history.push(`${this.props.location.pathname}?${qs.stringify(query)}`);
    }

    render() {
        const {isLoading, data, rowsPerPage, count} = this.state;
        
        return <Fragment>
            <Helmet>
                <title>QR Codes | Elicon</title>
            </Helmet>
            <Page permission={'view_qr_code_list'}>
                <Card>
                    <ListHeader
                        type="QRCodes"
                        title = "QR Codes"
                        searchTitle = "Filter QR Codes ..."
                    />
                    <ListBodyWithLoading
                        headers={QRCodeListHeaders}
                        isLoading={isLoading}
                        data={data}
                        count={count}
                        filters={QRCodeListFilters}
                        filterBy={'Availability'}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={this.onChangeRowsPerPage}
                        />
                </Card>
            </Page>
        </Fragment>
    };

}

function mapState(state) {
    return {
        query: qs.parse(state.router.location.search, { ignoreQueryPrefix: true })
    };
}

function mapActions(dispatch) {
    return bindActionCreators({push}, dispatch);
}

export default withRouter(connect(mapState, mapActions)(QRCodeList));
