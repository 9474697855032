




import React from 'react'
import { Form } from 'reactstrap'

import Button from '../../../UI/Button/Button'
import Input from '../../../UI/Input/Input'

import './styles.module.css'

const ReferralActionHeader = props => {
    return (
        <div>

        </div>
    )
}

export default ReferralActionHeader